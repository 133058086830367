export const de_common = {
    'teamSolution® NewDKS' : 'teamSolution® NewDKS DE',
    'Welcome to React': 'Welcome to React and react-i18next',
};

export const de_login = {
    'Einloggen' : 'Einloggen',
    'Anmelden' : 'Anmelden',
    'Zugangsdaten vergessen?' : 'Zugangsdaten vergessen?',
    'User' : 'Benutzername',
    'Password' : 'Passwort',
    'Zugangsdaten': 'Zugangsdaten vergessen?'
};

export const de_footer = {
    'Impressum' : 'Impressum',
    'Hilfe' : 'Hilfe',
};

export const de_corrector = {
    'PROJECTS': {
        'Projektübersicht': 'Projektübersicht',
        'Archiv': 'Archiv',
        'Neues Projekt anlegen': 'Neues Projekt anlegen',
        'Öffnen': 'Öffnen',
        'Name': 'Name',
        'Letzte Änderung': 'Letzte Änderung',
        'Erstellung': 'Erstellung',
        'Archiviert': 'Archiviert',
    },
    'CARD': {
        'Einstellungen': 'Einstellungen',
        'Phase:': 'Phase:',
        'Stufe': 'Stufe',
        'Erstellt:': 'Erstellt:',
        'Geändert:': 'Geändert:',
        'Archiviert:': 'Archiviert:',
        'WARNING1': 'Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'WARNING2': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.'
    },
    'ROW': {
        'Phase': 'Phase',
        'Stufe': 'Stufe',
        'Erstellt': 'Erstellt',
        'Geändert': 'Geändert',
        'Archiviert': 'Archiviert',
        'Korrekturen': 'Korrekturen',
        'Druckfreigabe': 'Druckfreigabe',
        'Öffnen': 'Öffnen',
        'Abbrechen': 'Abbrechen',
        'Archivieren': 'Archivieren',
        'Reaktivieren': 'Reaktivieren',
        'Projekt archivieren': 'Project {{name}} wirklich archivieren?',
        'Projekt privat archivieren': "Projekt als 'privat' archivieren.",
        'Privat projekt info': 'Das Projekt kann im Archiv nur von zugewiesenen Benutzer gefunden werden',
        'WARNING1': 'Achtung: Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'WARNING2': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.'
    },
    'PROJECTCARD': {
        'Korrekturstufe(n)': 'Korrekturstufe(n)',
        'Reaktivieren': 'Reaktivieren',
        'Archivieren': 'Archivieren',
        'Löschen': 'Löschen',
        'Einstellungen': 'Einstellungen',
    },
    'CORRECTIONLEVEL': {
        'Neue Korrekturstufe': 'Neue Korrekturstufe',
        'Projekteinstellungen': 'Projekteinstellungen',
        'Öffnen': 'Öffnen',
        'PDF herunterladen': 'PDF herunterladen',
        'Abbrechen': 'Abbrechen',
        'Archivieren': 'Archivieren',
        'archiviert': 'archiviert',
        'Archiviert': 'Archiviert',
        'Reaktivieren': 'Reaktivieren',
        'Löschen': 'Korrekturstufe löschen',
        'Projekt Archivieren': 'Projekt {{name}} wirklich archivieren?',
        'Projekt privat archivieren': "Projekt als 'privat' archivieren",
        'Projekt privat archivieren info': 'Das Projekt kann im Archiv nur von zugewiesenen Benutzer gefunden werden',
        'Korrekturstufe löschen': 'Korrekturstufe {{name}} wirklich löschen?',
        'Neues Projekt': 'Neues Projekt auf dieser Grundlage erstellen',
        'Alle Korrekturen': 'Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'Bitte schließen': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.',
        'Aktuelle Korrekturstufe': 'Aktuelle Korrekturstufe',
        'Druckfreigabe': 'Druckfreigabe am',
        'Stufe': 'Stufe',
        'Phase': 'Phase',
        'Erstellt': 'Erstellt',
        'Geändert': 'Geändert',
        'Korrekturen': 'Korrekturen',
        'Korrekturen insgesammt': 'Korrekturen insgesammt',
        'Korrekturen warten': 'Zu qualifizierende Korrekturen',
        'Korrekturen noch offen': 'Noch offene Korrekturen',
        'Korrekturen abgewiesen': 'Abgewiesene Korrekturen',
        'Korrekturen abgeschlossen': 'Abgeschlossen Korrekturen',
        'Termin für Korrekturen': 'Termin für Korrekturen',
        'Keine Korrekturstufe': 'Noch keine Korrekturstufe angelegt'
    },
    'TAGS': {
        'Schlagworte': 'Schlagworte',
        'Schlagworte ausbleden': 'Schlagworte ausbleden',
        'Schlagworte anzeigen': 'Schlagworte anzeigen',
    },
    'ATTENDEES': {
        'Teilnehmer': 'Teilnehmer',
        'Übernehmen': 'Übernehmen',
    },
    'CORRECTIONLEVELS': {
        'vorherige Korrekturstufen': 'vorherige Korrekturstufen',
        'Korrekturen': 'Korrekturen',
        'Korrekturen insgesammt': 'Korrekturen insgesammt',
        'Korrekturen warten': 'Korrekturen zu qualifizieren',
        'Korrekturen noch offen': 'Korrekturen noch offen',
        'Korrekturen abgewiesen': 'Korrekturen abgewiesen',
        'Korrekturen abgeschlossen': 'Korrekturen abgeschlossen',
        'Druckfreigabe am': 'Druckfreigabe am',
        'Öffnen': 'Öffnen',
        'PDF herunterladen': 'PDF herunterladen',
        'Stufe': 'Stufe',
        'Abgeschlossen': 'Abgeschlossen',
    },
    'PAGES': {
        'Kein Status': 'Kein Status',
        'in Bearbeitung': 'in Bearbeitung',
        'Abgeschlossen': 'Abgeschlossen',
        'Seite bei keinem Kontrolleur abgeschlossen':'Seite bei keinem Kontrolleur abgeschlossen',
        'Seite bei': 'Seite bei',
        'von': 'von',
        'Kontrolleuren abgeschlossen': 'Kontrolleuren abgeschlossen',
        'Seite': 'Seite',
    },
    'DEADLINE': {
        'Termin für Korrekturen setzen': 'Termin für Korrekturen setzen',
        'Abbrechen': 'Abbrechen',
        'Übernehmen': 'Übernehmen',
        'Termin für Korrekturen wurde geändert': 'Termin für Korrekturen wurde geändert',
    },
    'USERSTATES': {
        'Freigegeben': 'Freigegeben, alle Seiten abgeschlossen',
        'in Bearbeitung 1': 'in Bearbeitung, {{name}} Seiten abgeschlossen',
        'in Bearbeitung 2': 'in Bearbeitung, noch keine Seiten abgeschlossen',
        'Schließen': 'Schließen',
        'Alle Seiten abschließen': 'Alle Seiten abschließen',
        'Übersicht Benutzerstatus': 'Übersicht Benutzerstatus',
        'Kontrolleur': 'Kontrolleur',
        'Status': 'Status',
        'Gesamt': 'Gesamt',
        'Angenommen': 'Angenommen',
        'Abgelehnt': 'Abgelehnt',
        'Qualifiziert': 'Qualifiziert',
    },
    'EDITOR_COMMENTS': {
        'Kommentare': 'Kommentare',
        'Korrektur akzeptiert': 'Korrektur akzeptiert',
        'Korrektur abgelehnt': 'Korrektur abgelehnt',
        'Korrektur noch nicht qualifiziert': 'Korrektur noch nicht qualifiziert',
        'Korrektur offen': 'Korrektur offen',
        'Korrektur erledigt': 'Korrektur erledigt',
        'Korrektur hervorheben': 'Korrektur hervorheben',
        'Korrektur löschen': 'Korrektur löschen',
        'Auf Korrektur antworten': 'Auf Korrektur antworten',
        'Marker bearbeiten': 'Marker bearbeiten',
        'Als erledigt markieren': 'Als erledigt markieren',
        'Als offen markieren': 'Als offen markieren',
        'Qualifizieren': 'Qualifizieren',
        'Ablehnen': 'Ablehnen',
        'Korrekturen': 'Korrekturen',
        'Qualifiziert': 'Qualifiziert',
        'Abgelehnt': 'Abgelehnt',
        'Offen': 'Offen',
        'Erledigt': 'Erledigt',
        'Abbrechen': 'Abbrechen',
        'Korrektur wirklich löschen': 'Korrektur inklusive Kommentare wirklich löschen?',
    },
    'EDITOR_PAGES': {
        'Seiten': 'Seiten',
        'Seite': 'Seite',
        'Kein Status': 'Kein Status',
        'in Bearbeitung': 'in Bearbeitung',
        'Abgeschlossen': 'Abgeschlossen',
    },
    'EDITOR_MENU': {
        'Vergleichsansicht': 'Vergleichsansicht',
        'Standardansicht': 'Normalansicht',
        'Kontrolleure benachrichtigen': 'Kontrolleure benachrichtigen',
        'Werkzeuge u. Aktionen': 'Werkzeuge u. Aktionen',
        'Korrekturphase wechseln': 'Korrekturphase wechseln',
        'Abbrechen': 'Abbrechen',
        'Seite abschließen': 'Seite abschließen',
        'Seite wirklich abschließen': 'Seite wirklich abschließen? Die Aktion kann nicht rückgängig gemacht werden.',
        'Seite reaktivieren': 'Seite reaktivieren',
        'OK marker': 'OK Marker setzen',
        'Currency marker': 'Währungsmarker setzen',
        'Text marker': 'Textmarker setzen',
        'Area marker': 'Flächenmarker setzen',
    },
    'EDITOR_CONTEXTMENU': {
        'Bearbeiten': 'Bearbeiten',
        'Löschen': 'Löschen',
        'Abbrechen': 'Abbrechen',
        'Korrektur löschen': 'Korrektur löschen',
        'Korrektur wirklich löschen': 'Korrektur inklusive Kommentare wirklich löschen?',
    },
    'EDITOR_CORRECTION': {
        'Korrektur bearbeiten': 'Korrektur bearbeiten',
        'Was soll korrigert werden': 'Was soll korrigert werden?',
        'Nachricht hinzufügen': 'Nachricht hinzufügen...',
        'Anhang': 'Anhang',
        'Datei vom Computer': 'Datei vom Computer',
        'Abbrechen': 'Abbrechen',
        'bestätigen': 'bestätigen',
        'Durchsuchen': 'Durchsuchen'
    },
    'EDITOR_ADDCOMMENT': {
        'Kommentar': 'Kommentar',
        'Neuer Kommentar': 'Neuer Kommentar',
        'Anhang': 'Anhang',
        'Datei vom Computer': 'Datei vom Computer',
        'Abbrechen': 'Abbrechen',
        'Übernehmen': 'Übernehmen',
        'Durchsuchen': 'Durchsuchen'
    },
    'EDITOR_COMPARE': {
        'Vergleichsansicht': 'Vergleichsansicht',
        'Projekt zum Vergleichen auswählen': 'Projekt zum Vergleichen auswählen',
        'Projekt': 'Projekt',
        'Korrekturstufe': 'Korrekturstufe',
        'erste Seite': 'erste Seite',
        'vorherige Seite': 'vorherige Seite',
        'nächste Seite': 'nächste Seite',
        'letzte Seite': 'letzte Seite',
        'Schließen': 'Schließen',
        'Vergleichen': 'Vergleichen',
        'Vergleichsoptionen': 'Vergleichsoptionen'
    },




    'NEW': {
        'Projekt anlegen': 'Projekt anlegen',
        'Projektname': 'Projektname',
        'Schlagworte': 'Schlagworte',
        'Schlagwort hinzufügen': 'Schlagwort hinzufügen',
        'Doppelseitensplit': 'Doppelseitensplit',
        'Schließen': 'Schließen',
        'Anlegen': 'Anlegen'
    },
    'EDIT': {
        'Projekt bearbeiten': 'Projekt bearbeiten',
        'Projektname': 'Projektname',
        'Schlagworte': 'Schlagworte',
        'Schlagwort hinzufügen': 'Schlagwort hinzufügen',
        'Doppelseitensplit': 'Doppelseitensplit',
        'Schließen': 'Schließen',
        'Speichern': 'Speichern'
    },
    'NEWCL': {
        'Neue Korrekturstufe anlegen': 'Neue Korrekturstufe anlegen',
        'Titel': 'Titel',
        'Datei vom Computer': 'Datei vom Computer',
        'Schließen': 'Schließen',
        'Anlegen': 'Anlegen',
        'PDF wird verarbeitet...': 'PDF wird verarbeitet...',
        'Durchsuchen': 'Durchsuchen',
        'Korrekturstufe': 'Korrekturstufe'
    }
};

export const de_user = {
    'Welcome to React': 'Welcome to React and react-i18next',
    'BASICS': {
        'MESSAGES': {
            'Username can only consists of letters (en), numbers and underscore' : 'Benutzername darf nur Buchstaben (keine Umlaute), Ziffern und Unterstrich enthalten',
            'Username must consinst of at least three characters' : 'Der Benutzername muss aus mindestens drei Zeichen bestehen',
            'Username not available' : 'Benutzername nicht verfügbar',
            'Username is empty' : 'Benutzername darf nicht leer sein',
            'Email is empty' :  'E-Mail-Adresse darf nicht leer sein',
            'Invalid email address' :  'Ungültige E-Mail-Adresse',
            'Password must contain a special character' :  'Passwort muss mindestens ein Sonderzeichen enthalten',
            'Password must consist of at least 8 characters' :  'Passwort muss aus mindestens acht Zeichen bestehen',
            'Password must contain uppercase and lowercase characters' :  'Passwort muss Groß- und Kleinbuchstaben enthalten',
            'Password must contain a number' :  'Passwort muss mindestens eine Ziffer enthalten',
            'Passwords do not match' :  'Passworte stimmen nicht überein',
            'Password is empty' :  'Passwort darf nicht leer sein',
        },
        'LABELS': {
            'Username' : 'Benutzername',
            'E-Mail' : 'E-Mail',
            'Password' : 'Passwort',
            'Repeat Password' : 'Passwort wiederholen',
            'Active': 'Aktiv',
            'Language' : 'Sprache',
            
        },
    },
    'RESETPW': {
        'LABELS': {
            'Passwort setzen' : 'Passwort setzen',
            'Schließen' : 'Schließen',
            'Repeat Password' : 'Passwort Wdh.',
            'Password' : 'Passwort',
            'Username' : 'Benutzername',
            'Domain' : 'Mandant',
        },
    },
    'DETAILS': {
        'LABELS': {
            'forename' : 'Vorname',
            'surname' : 'Nachname',
            'company' : 'Firma',
            'department' : 'Abteilung',
        },
    },
    'ASSIGNMENTS': {
        'LABELS': {
            'Type' : 'Typ',
            'Select role' : 'Role wählen',
            'Teams' : 'Teams',
            'Select team' : 'Team(s) wählen',
            'Default Role': 'Standart-Role',
            'Select type' : 'Typ wählen',
        }
    },
    'SETTINGS': {
        'LABELS': {
            'E-Mail Benachrichtigungen': 'E-Mail Benachrichtigungen',
            'interne Benachrichtigungen': 'interne Benachrichtigungen',
            'Kommentare automatisch aufklappen': 'Kommentare automatisch aufklappen',
        }
    },
    'PROJECTS': {
        'LABELS': {
            'Eigene Projekte': 'Eigene Projekte',
            'Benutzer zu vorhandenen Projekte hinzufügen': 'Benutzer zu vorhandenen Projekte hinzufügen',
            'Rolle wählen': 'Rolle wählen',
        },
        'BUTTONS' : {
            'zu Projekt hinzufügen' : 'zu Projekt hinzufügen',
            'hinzufügen': 'hinzufügen',
            'abbrechen': 'abbrechen'
        }
    },
    'EDIT': {
        'MESSAGES': {
            'Remove user' : 'Benutzer {{name}} wirklich entfernen?',
            'Remove user info': 'Der Benutzer {{name}} wird aus allen Teams und Projekten entfernt'
        },
        'LABELS': {
            'Edit User': 'Benutzer {{name}} bearbeiten',
        },
        'BUTTONS' : {
            'Abort' : 'Abbrechen',
            'Remove' : 'Entfernen',
            'Save' : 'Speichern',
            'Close' : 'Schließen',
        }
    },
    'NEW': {
        'LABELS': {
            'Benutzer anlegen' : 'Benutzer anlegen',
        },
        'BUTTONS': {
            'Abort' : 'Abbrechen',
            'Create' : 'Anlegen',
        }
    },
    'LIST': {
        'LABELS': {
            'User list': 'Benutzerübersicht',
            'Search' : 'Suchen',
            'Additional teams:': 'Weitere Teams:',
            'Username' : 'Benutzername',
            'Full name' : 'Name',
            'Last login' : 'Letzte Anmeldung',
            'E-Mail' : 'E-Mail',
            'Company' : 'Firma',
            'Department' : 'Abteilung',
            'forename' : 'Vorname',
            'surname' : 'Nachname',
            'company' : 'Firma',
            'department' : 'Abteilung',
            'Type' : 'Typ',
            'Teams' : 'Teams',
        },
        'BUTTONS': {
            'Close': 'Schließen',
            'Add user': 'Benutzer hinzufügen'
        }
    },
};

export const de_archive = {
    'PROJECTS': {
        'Projektübersicht': 'Projektübersicht',
        'Archiv': 'Archiv',
    },
}

export const de_dashboard = {
    'LABELS': {
        'Kunden' : 'Kunden',
        'Neuigkeiten': 'Neuigkeiten',
        'Als gelesen markieren': 'Als gelesen markieren',
        'Als ungelesen markieren': 'Als ungelesen markieren',
        'Aktualisieren': 'Aktualisieren'
    },
    'BUTTONS': {
        'Kundenliste ausblenden' : 'Kundenliste ausblenden',
        'Kundenliste einblenden': 'Kundenliste einblenden',
        'Neuigkeiten ausblenden' : 'Neuigkeiten ausblenden',
        'Neuigkeiten einblenden': 'Neuigkeiten einblenden',
    },
    'MENU': {
        'Abmelden': 'Abmelden',
        'System': 'System',
        'Profil': 'Profil',
        'Archiv': 'Archiv',
        'Dashboard': 'Dashboard',
        'Seite': 'Seite'
    },
    'SYSTEM': {
        'Systemeinstellungen': 'Systemeinstellungen',
        'Manage your preferences': 'Systemeinstellungen verwalten',
        'Einstellungen': 'Einstellungen',
        'Benutzerliste': 'Benutzerliste',
        'Benutzer verwalten und anlegen': 'Benutzer verwalten und anlegen',
        'Teams': 'Teams',
        'Teams verwalten und anlegen': 'Teams verwalten und anlegen',
        'Rollen': 'Rollen',
        'Rollen verwalten und anlegen': 'Rollen verwalten und anlegen',
        'Schlagworte': 'Schlagworte',
        'Schlagworte verwalten und anlegen': 'Schlagworte verwalten und anlegen',
        'Change log': 'Changelog',
        'aktuelle Version': 'aktuelle Version',
        'Textvorlagen': 'Textvorlagen',
        'Textvorlagen verwalten und anlegen': 'Textvorlagen verwalten und anlegen'
    },

    'TEXTTEMPLATES': {
        'Textvorlagen': 'Textvorlagen',
        'Name der Textvorlage': 'Name der Textvorlage',
        'Abbrechen': 'Abbrechen',
        'Löschen': 'Löschen',
        "Textvorlage '": "Textvorlage '",  
        "' wirklich löschen?": "' wirklich löschen?",
        'neuer Vorlagentext': 'neuer Vorlagentext',
        'Schließen': 'Schließen',
        'Hinzufügen': 'Hinzufügen'
    },

    MULTIMESSAGE: {
        'Kontrolleure benachrichtigen': 'Kontrolleure benachrichtigen',
        'Nachricht': 'Nachricht',
        'Nachricht hinzufügen': 'Nachricht hinzufügen',
        'Textvorlagen': 'Textvorlagen',
        'Übernehmen': 'Übernehmen',
        'Empfängereinschränkungen': 'Empfängereinschränkungen',
        'Nur Kontrolleure benachrichtigen, die noch nicht abgeschlossen haben': 'Nur Kontrolleure benachrichtigen, die noch nicht abgeschlossen haben',
        'Kontrolleure': 'Kontrolleure',
        'Schließen': 'Schließen',
        'Benachrichtigen': 'Benachrichtigen',
    }
};