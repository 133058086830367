// react base
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { dataCorrectionLevel, dataProject } from './corrector.slice';
import { initCorrectionsFromDB } from './editor/editor.slice';
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../WECorrect.slice';
import { selectUser } from '../login/login.slice';
import { selectAttendees } from './project/attendees.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// App
import WECCorrectorCLDetails from "./correctionlevel/details";

// semantic ui
import { Icon, Segment, Divider, Header } from 'semantic-ui-react'

// Styles
import './corrector.css';



/**
 * 
 * @param {Array} pages 
 */
 const WECCorrectorPages = () => {
  const dispatch = useDispatch();
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);
  const c_correctionLevel = useSelector(dataCorrectionLevel);
  const c_project = useSelector(dataProject);
  const attendees = useSelector(selectAttendees);
  const config = useSelector(selectConfig);
  const _user = useSelector(selectUser);

  // translation
  const { t } = useTranslation('corrector');

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_CLV_01 = permissionHandler.hasPermission(_user, 'PERM_CLV_01', c_project.attendees, config[config.domain].user.roles);

  // determine correctors and managers role
  let ids_role_corrector = [];
  let ids_role_manager = [];
  const roles = config[config.domain].user.roles;
  for (const key in roles) {
    if (Object.hasOwnProperty.call(roles, key)) {
      const role = roles[key];
      if (role.type === 'S_CORRECTOR') {
        ids_role_corrector.push(role.id)
      }
      if (role.type === 'S_ADMIN') {
        ids_role_manager.push(role.id)
      }  
    }
  }
  
  // determine correctors and managers
  let correctors = [];
  let is_manager = false;
  for (const roleId in attendees) {
    if (Object.hasOwnProperty.call(attendees, roleId)) {
      if (ids_role_corrector.includes(parseInt(roleId))) {
        const roleAttendees = attendees[roleId];
        roleAttendees.forEach(roleAttendee => {
          correctors.push(roleAttendee);
        });
      }
      if (ids_role_manager.includes(parseInt(roleId))) {
        const roleAttendees = attendees[roleId];
        roleAttendees.forEach((roleAttendee) => {
          if (roleAttendee.userid === _user.id) {
            is_manager = true;
          }
        });
      }
    }
  }

  //  
  return (
    <Segment className='WECProject wec_corrector_pages'>
      <Header as='h2'>{c_project.title}</Header>
      <WECCorrectorCLDetails />
      <Divider />
      <div className='pages'>
        {c_correctionLevel.pages.map((_page, i) => {
          const src = c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + c_correctionLevel.id+'/thumbs/' + _page.pageNr + '.jpg';
          
          // user state text 
          let _state = t('corrector:PAGES.Kein Status');
          if (typeof _page.userstate !== 'undefined') {
            switch(_page.userstate.state) {
              case 'open':  _state = t('corrector:PAGES.in Bearbeitung'); break;
              case 'locked': _state = t('corrector:PAGES.Abgeschlossen'); break;
              default: _state = t('corrector:PAGES.Kein Status');
            }
          }

          // progress overlay
          let progress = 0;
          correctors.forEach(corrector => {
            _page.userstates.forEach(userstate => {
              if (corrector.userid === userstate.user && userstate.state === 'locked') {
                progress++;
              }
            });
          });
          let progressOverlayTxt = progress + '/' + correctors.length;
          let progressOverlayTooltipp = progress ? t('corrector:PAGES.Seite bei') + ' ' + progress + ' ' + t('corrector:PAGES.von') + ' ' + correctors.length + ' ' + t('corrector:Kontrolleuren abgeschlossen') : t('corrector:PAGES.Seite bei keinem Kontrolleur abgeschlossen');


          // page image & status check icon(s)
          let progressOverlay = <></>;
          if (is_manager) {
            /*progressOverlay = (
              <div className='checkmarkOverlay progress'>
                <span title={progressOverlayTooltipp}>{progressOverlayTxt}</span>
              </div>
            );*/
            progressOverlay = <span title={progressOverlayTooltipp}>({progressOverlayTxt})</span>;
          }

          // open page event
          //const onPageClick = permission_PERM_CLV_01 ? ()=>{dispatch(selectPage({id: _page.id, key: i})); dispatch(initCorrections(_page.corrections));} : ()=>{};
          const onPageClick = permission_PERM_CLV_01 ? ()=>{ dispatch(initCorrectionsFromDB(_page.domain, _page.id, i, true )); } : ()=>{};
          
          // 
          if (typeof _page.userstate !== 'undefined' && typeof _page.userstate.state !== 'undefined' && _page.userstate.state === 'locked' && !is_manager) {
            return (
              <div className='page' key={i} title={_state} onClick={onPageClick}>
                <div class="image thumbS">
                  <img alt='checked' className='checked' src={src} />
                  <div className='checkmarkOverlay'>
                    <Icon name='checkmark' size='massive' />
                  </div>
                </div>
                <div className={'page_number'}>
                  {t('corrector:PAGES.Seite')} {_page.pageNr} {progressOverlay}
                </div>
              </div>
            );
          } else {
            return (
              <div className='page' key={i} title={_state} onClick={onPageClick}>
                <div class="image thumbS">
                  <img alt='not_checked' src={src} />
                </div>
                <div className={'page_number'}>
                  {t('corrector:PAGES.Seite')} {_page.pageNr} {progressOverlay}
                </div>
              </div>
            );
          }
          
          // eslint-disable-next-line
          return <></>;
        })}
      </div>
    </Segment>
  );
}

export default WECCorrectorPages;