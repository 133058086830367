

/**
 * 
 */
class WECPermissionHandler {

  /**
   * 
   */
  constructor() {
    this.rolePermissions = {
      PERM_SYS_01: { group: 'SYSTEM', name: 'Benutzer einsehen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_02: { group: 'SYSTEM', name: 'Benutzer anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_03: { group: 'SYSTEM', name: 'Benutzer bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_04: { group: 'SYSTEM', name: 'Teams einsehen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_05: { group: 'SYSTEM', name: 'Teams anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_06: { group: 'SYSTEM', name: 'Teams bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_07: { group: 'SYSTEM', name: 'Rollen einsehen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_08: { group: 'SYSTEM', name: 'Rollen anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_09: { group: 'SYSTEM', name: 'Rollen bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_10: { group: 'SYSTEM', name: 'Schlagworte einsehen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_11: { group: 'SYSTEM', name: 'Schlagworte anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_12: { group: 'SYSTEM', name: 'Schlagworte bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_13: { group: 'SYSTEM', name: 'Textvorlagen einsehen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_14: { group: 'SYSTEM', name: 'Textvorlagen anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_SYS_15: { group: 'SYSTEM', name: 'Textvorlagen bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },

      PERM_DSH_01: { group: 'DASHBOARD', name: 'Projekt anlegen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_DSH_02: { group: 'DASHBOARD', name: 'Mandantenwähler', description: 'Lorem Ipsum dolor set amet consectetur' },

      PERM_PRJ_01: { group: 'PROJECT', name: 'Korrekturstufe hinzufügen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_02: { group: 'PROJECT', name: 'Korrekturstufe öffnen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_03: { group: 'PROJECT', name: 'Projekt archivieren', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_04: { group: 'PROJECT', name: 'Projekeinstellungen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_05: { group: 'PROJECT', name: 'PDF herunterladen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_06: { group: 'PROJECT', name: 'Vorherige Korrekturstufe öffnen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_07: { group: 'PROJECT', name: 'PDF vorherige Korrekturstufe', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_08: { group: 'PROJECT', name: 'Projekt kopieren', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_09: { group: 'PROJECT', name: 'Korrekturstufe löschen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_10: { group: 'PROJECT', name: 'Teilnehmer hinzufügen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_PRJ_11: { group: 'PROJECT', name: 'Einstellungen ändern', description: 'Lorem Ipsum dolor set amet consectetur' },

      PERM_CLV_01: { group: 'CORRECTIONLEVEL', name: 'Seite öffnen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_CLV_02: { group: 'CORRECTIONLEVEL', name: 'Korrekturstufe abschließen/freigeben', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_CLV_03: { group: 'CORRECTIONLEVEL', name: 'Termin f. Korrekturen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_CLV_04: { group: 'CORRECTIONLEVEL', name: 'Korrekturenübersicht', description: 'Lorem Ipsum dolor set amet consectetur' },

      PERM_EDT_01: { group: 'EDITOR', name: 'Korrektur Fläche setzen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_02: { group: 'EDITOR', name: 'Korrektur Text setzen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_03: { group: 'EDITOR', name: 'Korrektur Euro setzen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_04: { group: 'EDITOR', name: 'Korrektur OK setzen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_05: { group: 'EDITOR', name: 'Korrektur bearbeiten', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_06: { group: 'EDITOR', name: 'Seite abschließen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_07: { group: 'EDITOR', name: 'Korrekturphase wechseln', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_08: { group: 'EDITOR', name: 'Korrektur qualifizieren', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_09: { group: 'EDITOR', name: 'Korrektur erledigt (privat)', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_10: { group: 'EDITOR', name: 'Kommentar zur Korrektur hinzufügen', description: 'Lorem Ipsum dolor set amet consectetur' },
      PERM_EDT_13: { group: 'EDITOR', name: 'Vergleichsansicht', description: 'Lorem Ipsum dolor set amet consectetur' },
    }
  }



  /**
   * 
   * @returns {Object}
   */
  getPermissions() {
    return this.rolePermissions;
  }

    

  /**
   * 
   * @param {Object} user 
   * @param {String} perm_id 
   * @param {Array} attendees
   * @param {Array} sysRoles
   * @returns {Boolean}
   */
  hasPermission(user, perm_id, attendees, sysRoles) {
    try {
      if (typeof user === 'undefined' || !user) {
        throw new Error('No valid user given');
      }
  
      if (typeof user.roleObject === 'undefined' || !user.roleObject) {
        throw new Error('User has no default role');
      }
  
      // check if given permission id is defined
      if (!Object.hasOwnProperty.call(this.rolePermissions, perm_id)) {
        throw new Error('Given permission not defined');
      }
      let hasDefautlRole = true;
      if (typeof user.roleObject === 'undefined' || !user.roleObject || typeof user.roleObject.permissions === 'undefined' || !user.roleObject.permissions) {
        hasDefautlRole = true;
      }

      // check if given user (role) has pemission
      let permission = false; 
      if (typeof attendees === 'undefined' || !attendees) {
        if (hasDefautlRole && typeof user.roleObject.permissions[perm_id] !== 'undefined' && typeof user.roleObject.permissions[perm_id].state !== 'undefined' && user.roleObject.permissions[perm_id].state)  {
          permission = true;
        }
      }
      if (typeof attendees !== 'undefined' && attendees) {
        attendees.forEach(attendee => {
          if (attendee.userid === user.id) {
            for (const key in sysRoles) {
              if (Object.hasOwnProperty.call(sysRoles, key)) {
                const sysRole = sysRoles[key];
                if (sysRole.id === attendee.role) {
                  if (typeof sysRole.permissions[perm_id] !== 'undefined' && typeof sysRole.permissions[perm_id].state !== 'undefined' && sysRole.permissions[perm_id].state)  {
                    permission = true;
                  }
                }
              }
            }
          }
        });
      }
      if (permission) {
        return true;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }

    return false;
  }
}
  
export default WECPermissionHandler;