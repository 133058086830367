// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { abort, setMousePosition, checkDeselect, setDragMode, setSelectMode, resizeRect, isDrawRect, startDrawRect, stopDrawRect, selectedColor, selectedTool, setSelectedColor, setSelectedTool, setScale, setDirtyCorrection  } from './editor.slice';
import { scale, correctionMode, addMode, compareMode } from './editor.slice';
import { corrections, selectedCorrection, lastCorrection, saveCorrection } from './editor.slice';
import { selectCorrection, unselectCorrection, setCorrection, addCorrection } from './editor.slice';
import { dataPage, dataCorrectionLevel, dataProject, selectResetInitialZoomLevel, unsetResetInitialZoomLevel, setDataCorrection } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../../WECorrect.slice';
import { showDialog, setToolMessage } from './dialogNewCorrection.slice';
import { setRefresh } from './dialogCorrection.slice';
import { showContextMenu } from '../contextMenu/contextMenu.slice';
import { selectUser } from '../../login/login.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// Konvajs
import { Stage, Layer, Line } from 'react-konva';

// App
import WECCorrectorEditorMenu from "./menu"
import WECCorrectorEditorPageImage from "./pageImage"
import WECCorrectorEditorRectangle from "./rectangle"
import WECCorrectorEditorIcon from "./icon"

// Styles
import './editor.css';



/**
 * @params {Boolean} readOnly
 * @returns 
 */
const WECCorrectorEditorEditor = ({readOnly}) => {
  // local state
  const [initialZoomlevel, setInitialZoomlevel] = useState(null);
  const [dragPage, setDragPage] = useState(false);
  const [dragStart, setDragStart] = useState(null);

  // global state
  const dispatch = useDispatch();
  const selectedId              = useSelector(selectedCorrection);
  const initialScale            = useSelector(scale);
  const c_addMode               = useSelector(addMode);
  const e_compareMode           = useSelector(compareMode)
  const e_correctionMode        = useSelector(correctionMode);
  const c_project               = useSelector(dataProject);
  const c_correctionLevel       = useSelector(dataCorrectionLevel);
  const c_dataPage              = useSelector(dataPage);
  const c_selectBaseProtocol    = useSelector(selectBaseProtocol);
  const s_selectBackendUrl      = useSelector(selectBackendUrl);
  const e_corrections           = useSelector(corrections);
  const e_lastCorrection        = useSelector(lastCorrection);
  const e_isDrawRect            = useSelector(isDrawRect);
  const e_selectedColor         = useSelector(selectedColor)
  const e_selectedTool          = useSelector(selectedTool)
  const c_resetInitialZoomLevel = useSelector(selectResetInitialZoomLevel);
  const _config                 = useSelector(selectConfig);
  const userLoggedIn            = useSelector(selectUser);
  let _corrections = e_corrections;

  // React Component References
  const stageRef = React.useRef();

  // initiale Zoomstufe
  // eslint-disable-next-line
  useEffect(() => {
    if (c_resetInitialZoomLevel) {
      setInitialZoomlevel(null);
      dispatch(unsetResetInitialZoomLevel());
    }
    if (!initialZoomlevel) {
      const canvasDimensions = document.getElementById('corrector_editor_canvas') ? document.getElementById('corrector_editor_canvas').getBoundingClientRect() : {}
      const ratio = {
        width:  Math.floor((canvasDimensions.width * 100) / c_dataPage.width),
        height:  Math.floor((canvasDimensions.height * 100) / c_dataPage.height)
      }
      const maxScale = Math.floor(Math.max(ratio.width, ratio.height) / 10) * 10 ;

      setInitialZoomlevel(maxScale > 100 ? 100 : maxScale < 20 ? 20 : maxScale);
      dispatch(setScale(maxScale > 100 ? 100 : maxScale < 20 ? 20 : maxScale));
    }
  }, [c_resetInitialZoomLevel, initialZoomlevel, dispatch, c_dataPage.width, c_dataPage.height]);

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_EDT_05 = permissionHandler.hasPermission(userLoggedIn, 'PERM_EDT_05', c_project.attendees, _config[_config.domain].user.roles) && userLoggedIn.type !== 4;

  // checks if page given  
  if (typeof c_dataPage === 'undefined' || c_dataPage === null) {
    return (<></>);
  }

  // build up tools list
  let tools = {}
  _config[_config.domain].toolbar.tool.tools.forEach(tool => {
    tools[tool[0]] = {
      id: tool[0],
      name: tool[1],
      icon: tool[2],
      text: tool[3]
    }
  });

  // sets default selected color
  if (!e_selectedColor) {
    dispatch(setSelectedColor(_config[_config.domain].toolbar.area.colors[0]))
  }

  // sets default selected tool
  if (!e_selectedTool) {
    dispatch(setSelectedTool(_config[_config.domain].toolbar.tool.tools[0][0]))
  }

  // Ruler 
  let lines = [];
  /*const xEnd = c_dataPage.width * initialScale;
  const yEnd = c_dataPage.height * initialScale;
  const step = Math.ceil(100  * initialScale);
  for (let i=0; i<=xEnd; i=i+step) {
    lines.push({
      points:[i, 0, i, 10]
    });
  }
  for (let i=0; i<=yEnd; i=i+step) {
    lines.push({
      points:[0, i, 10, i]
    });
  }*/


  return (
    <div className='corrector_editor'>
      <div className='Menu'>
        {e_compareMode === 'compare' && (<WECCorrectorEditorMenu readOnly={readOnly} compare='compare' />)}
        {e_compareMode === 'normal' && (<WECCorrectorEditorMenu readOnly={readOnly} />)}
      </div>
      <div id='corrector_editor_canvas' className='corrector_editor_canvas'>
        <Stage
          ref = {stageRef}
          width = {c_dataPage.width * initialScale} 
          height = {c_dataPage.height * initialScale}
          onTouchStart = {checkDeselect}
          onMouseDown = {(event) => {
            if (e_correctionMode && c_addMode === 'rect' && !readOnly) {
              dispatch(addCorrection({size: _config[_config.domain].toolbar.area.defaultSize}))
              dispatch(startDrawRect());
            } else {
              dispatch(checkDeselect(event))

              //start scoll
              if (!dragPage) {
                setDragStart({ x: event.evt.offsetX, y: event.evt.offsetY })
              }
              setDragPage(true);
            }
          }}
          onMouseUp = {(event) => {
            if (e_correctionMode && c_addMode === 'rect' && !readOnly) {
              dispatch(stopDrawRect());
              dispatch(showDialog({domain: c_project.domain, correction: e_lastCorrection}))
            } else {
              // end scroll
              if (dragPage && dragStart) {
                setDragStart(null);
                setDragPage(false);
              }
            }
          }}
          onMouseOut = {(event) => {
            // end scroll
            if (dragPage && dragStart) {
              setDragStart(null);
              setDragPage(false);
            }
          }}
          onClick = {(event) => {
            if (e_correctionMode && c_addMode !== 'select' && c_addMode !== 'drag' && c_addMode !== 'rect' && !readOnly) {
              dispatch(addCorrection({size: _config[_config.domain].toolbar[c_addMode].defaultSize}))
              dispatch(showDialog({domain: c_project.domain, correction: e_lastCorrection}))
              if (c_addMode === 'tool') {
                dispatch(setToolMessage(tools[e_selectedTool].text))
              }
            }
          }}
          onMouseMove = { (event) => {
            dispatch(setMousePosition({ x: event.evt.offsetX, y: event.evt.offsetY }))
            if (e_correctionMode && c_addMode === 'rect' && e_isDrawRect && !readOnly) {
              dispatch(resizeRect())
            } else {
              if (dragPage && dragStart) {
                const canvas = document.querySelector('.corrector_editor_canvas');
                canvas.scrollTo({
                  top: ( canvas.scrollTop + (dragStart.y - event.evt.offsetY)),
                  left: ( canvas.scrollLeft + (dragStart.x - event.evt.offsetX)),
                  behavior: "smooth"
                });
              }
            }
          }}
        >
          <Layer>
            <WECCorrectorEditorPageImage 
              imageProps = {c_dataPage} 
              imageURL = {c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + c_correctionLevel.id + '/pages/'} 
              scale = {initialScale} 
              onClick = {()=>dispatch(unselectCorrection())}
            />
            {lines.map((line, i) => (
              <Line
                key = {i}
                points = {line.points}
                stroke = "#333"
                strokeWidth = {2}
                tension = {0.5}
                lineCap = "round"
              />
            ))}
            {_corrections.map((correction, i) => {
              const _dirty = correction.dirty;
              switch (correction.marker.type) {
                case 'euro':
                  return (
                    <WECCorrectorEditorIcon
                      permissionEdit = {permission_PERM_EDT_05}
                      readOnly={readOnly}
                      key = {i}
                      id = {correction.id}
                      icon = {'/media/tools/' + _config[_config.domain].toolbar.euro.icon}
                      shapeProps = {correction.marker}
                      isSelected = {correction.id === selectedId}
                      isCorrectionMode = {e_correctionMode}
                      isDirty = {_dirty}
                      mode = {c_addMode}
                      correction = {correction}
                      onRightClick = {(position)=>{
                        dispatch(setRefresh(true))
                        dispatch(showContextMenu(position))
                      }}
                      onAbort = {()=>dispatch(abort(correction))}
                      onSave = {()=>{
                        dispatch(saveCorrection(correction));

                        dispatch(setDirtyCorrection({ckey: i, dirty: false}));
                        let _correction = Object.assign({}, correction);
                        _correction.dirty = false;
                        const ckey = _correction.cnt - 1;
                        let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                        dispatch(setDataCorrection(data));
                      }}
                      onSelect = {() => {
                        dispatch(selectCorrection(correction.id))
                        const comment = document.querySelector('#comment_' + correction.id);
                        const commentslist = document.querySelector('.commentslist');
                        commentslist.scrollTo({
                          top: ( comment.offsetTop - 190),
                          behavior: "smooth"
                        });
                      }}
                      onChange = {(newAttrs) => {
                        if (e_correctionMode && !readOnly) {
                          let _correction = Object.assign({}, correction);
                          _correction.marker = newAttrs;
                          dispatch(setCorrection(_correction));
                          
                          const ckey = _correction.cnt - 1;
                          let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                          dispatch(setDataCorrection(data));
                        }
                      }}
                      onDragStart = {() => { if (e_correctionMode && !readOnly) { dispatch(setDragMode()) } }}
                      onDragEnd = {() => { 
                        if (e_correctionMode && !readOnly) { 
                          dispatch(setSelectMode())
                          dispatch(selectCorrection(correction.id))
                        }
                      }}
                      onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                      onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                    />
                  );
                case 'ok':
                  return (
                    <WECCorrectorEditorIcon
                      permissionEdit = {permission_PERM_EDT_05}
                      readOnly={readOnly}
                      key = {i}
                      id = {correction.id}
                      icon = {'/media/tools/' + _config[_config.domain].toolbar.ok.icon}
                      shapeProps = {correction.marker}
                      isSelected = {correction.id === selectedId}
                      isCorrectionMode = {e_correctionMode}
                      isDirty = {_dirty}
                      mode = {c_addMode}
                      correction = {correction}
                      onRightClick = {(position)=>{
                        dispatch(setRefresh(true))
                        dispatch(showContextMenu(position))
                      }}
                      onAbort = {()=>dispatch(abort(correction))}
                      onSave = {()=>{
                        dispatch(saveCorrection(correction));

                        dispatch(setDirtyCorrection({ckey: i, dirty: false}));
                        let _correction = Object.assign({}, correction);
                        _correction.dirty = false;
                        const ckey = _correction.cnt - 1;
                        let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                        dispatch(setDataCorrection(data));
                      }}
                      onSelect = {() => {
                        dispatch(selectCorrection(correction.id))
                        const comment = document.querySelector('#comment_' + correction.id);
                        const commentslist = document.querySelector('.commentslist');
                        commentslist.scrollTo({
                          top: ( comment.offsetTop - 190),
                          behavior: "smooth"
                        });
                      }}
                      onChange = {(newAttrs) => {
                        if (e_correctionMode && !readOnly) {
                          let _correction = Object.assign({}, correction);
                          _correction.marker = newAttrs;
                          dispatch(setCorrection(_correction));

                          const ckey = _correction.cnt - 1;
                          let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                          dispatch(setDataCorrection(data));
                        }
                      }}
                      onDragStart = {() => { if (e_correctionMode && !readOnly) { dispatch(setDragMode()) } }}
                      onDragEnd = {() => { 
                        if (e_correctionMode && !readOnly) { 
                          dispatch(setSelectMode())
                          dispatch(selectCorrection(correction.id))
                        }
                      }}
                      onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                      onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                    />
                  );
                case 'tool':
                  const icon = '/media/tools/' +  tools[correction.marker.tool].icon
                  return (
                    <WECCorrectorEditorIcon
                      permissionEdit = {permission_PERM_EDT_05}
                      readOnly={readOnly}
                      key = {i}
                      id = {correction.id}
                      icon = {icon}
                      shapeProps = {correction.marker}
                      isSelected = {correction.id === selectedId}
                      isCorrectionMode = {e_correctionMode}
                      isDirty = {_dirty}
                      mode = {c_addMode}
                      correction = {correction}
                      onRightClick = {(position)=>{
                        dispatch(setRefresh(true))
                        dispatch(showContextMenu(position))
                      }}
                      onAbort = {()=>dispatch(abort(correction))}
                      onSave = {()=>{
                        dispatch(saveCorrection(correction));

                        dispatch(setDirtyCorrection({ckey: i, dirty: false}));
                        let _correction = Object.assign({}, correction);
                        _correction.dirty = false;
                        const ckey = _correction.cnt - 1;
                        let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                        dispatch(setDataCorrection(data));
                      }}
                      onSelect = {() => {
                        dispatch(selectCorrection(correction.id))
                        const comment = document.querySelector('#comment_' + correction.id);
                        const commentslist = document.querySelector('.commentslist');
                        commentslist.scrollTo({
                          top: ( comment.offsetTop - 190),
                          behavior: "smooth"
                        });
                      }}
                      onChange = {(newAttrs) => {
                        if (e_correctionMode && !readOnly) {
                          let _correction = Object.assign({}, correction);
                          _correction.marker = newAttrs;
                          dispatch(setCorrection(_correction));

                          const ckey = _correction.cnt - 1;
                          let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                          dispatch(setDataCorrection(data));
                        }
                      }}
                      onDragStart = {() => { if (e_correctionMode && !readOnly) { dispatch(setDragMode()) } }}
                      onDragEnd = {() => { 
                        if (e_correctionMode && !readOnly) { 
                          dispatch(setSelectMode())
                          dispatch(selectCorrection(correction.id))
                        }
                      }}
                      onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                      onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                    />
                  );
                default:
                  return (
                    <WECCorrectorEditorRectangle
                      permissionEdit = {permission_PERM_EDT_05}
                      readOnly={readOnly}
                      key = {i}
                      id = {correction.id}
                      shapeProps = {correction.marker}
                      isSelected = {correction.id === selectedId}
                      isCorrectionMode = {e_correctionMode}
                      isDirty = {_dirty}
                      mode = {c_addMode}
                      correction = {correction}
                      onRightClick = {(position)=>{
                        dispatch(setRefresh(true))
                        dispatch(showContextMenu(position))
                      }}
                      onAbort = {()=>dispatch(abort(correction))}
                      onSave = {()=>{
                        dispatch(saveCorrection(correction));
                        dispatch(setDirtyCorrection({ckey: i, dirty: false}));
                        let _correction = Object.assign({}, correction);
                        _correction.dirty = false;
                        const ckey = _correction.cnt - 1;
                        let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                        dispatch(setDataCorrection(data));
                      }}
                      onSelect = {() => {
                        dispatch(selectCorrection(correction.id))
                        const comment = document.querySelector('#comment_' + correction.id);
                        const commentslist = document.querySelector('.commentslist');
                        commentslist.scrollTo({
                          top: ( comment.offsetTop - 190),
                          behavior: "smooth"
                        });
                      }}
                      onChange = {(newAttrs) => {
                        if (e_correctionMode && !readOnly) {
                          let _correction = Object.assign({}, correction);
                          _correction.marker = newAttrs;
                          dispatch(setCorrection(_correction));
                          const ckey = _correction.cnt - 1;
                          let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                          dispatch(setDataCorrection(data));
                        }
                      }}
                      onChangeTransform = {(newAttrs) => {
                        if (e_correctionMode && !readOnly) {
                          let _correction = Object.assign({}, correction);
                          _correction.marker = newAttrs;
                          dispatch(setCorrection(_correction));
                          const ckey = _correction.cnt - 1;
                          let data = {page_nr: c_dataPage.pageNr - 1, correction_nr: ckey, correction: _correction}
                          dispatch(setDataCorrection(data));
                        }
                      }}
                      onDragStart = {() => { if (e_correctionMode && !readOnly) { dispatch(setDragMode()) } }}
                      onDragEnd = {() => { 
                        if (e_correctionMode && !readOnly) { 
                          dispatch(setSelectMode())
                          dispatch(selectCorrection(correction.id))
                        }
                      }}
                      onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                      onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                    />
                  );
              }
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}


export default WECCorrectorEditorEditor;