import { createSlice } from '@reduxjs/toolkit';



/**
 * 
 */
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    messages: [],
    seq: 0
  },

  reducers: {
    addMessage: (state, action) => {
      state.seq++;
      const _message = {
        id: state.seq,
        type: action.payload.type,
        header: action.payload.header,
        text: action.payload.text,
        created: Math.floor(Date.now() / 1000),
        timeout: 5000
      }
      state.messages.push(_message);
    },
    deleteMessage: (state, action) => {
      for (const key in state.messages) {
        if (typeof state.messages !== 'undefined' && state.messages[key] != null && Object.hasOwnProperty.call(state.messages, key)) {
          const element = state.messages[key];
          if (element.id === action.payload) {
            delete state.messages[key];
          }
        }
      }
    },
  },
});

export const { addMessage, deleteMessage  } = snackbarSlice.actions;

export const messages = (state) => state.snackbar.messages;


export default snackbarSlice.reducer;