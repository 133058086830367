export const en_common =
{
    'teamSolution® NewDKS' : 'teamSolution® NewDKS',
    'Welcome to React': 'Welcome to React and react-i18next',
};

export const en_login = {
    'Einloggen' : 'Login',
    'Anmelden' : 'Login',
    'Zugangsdaten vergessen?' : 'Forgot credentials?',
    'User' : 'Username',
    'Password' : 'Password',
};

export const en_footer = {
    'Impressum' : 'Impressum',
    'Hilfe' : 'Help',
};

export const en_corrector = {
    'PROJECTS': {
        'Projektübersicht': 'Projects',
        'Archiv': 'Archive',
        'Neues Projekt anlegen': 'Create new projekt',
        'Öffnen': 'Open',
        'Name': 'Name',
        'Letzte Änderung': 'Last change',
        'Erstellung': 'Created',
        'Archiviert': 'Archived',
        'Suche': 'Search',
        'Aktualisieren': 'Refresh',
        'Listenansicht': 'List view',
        'Kachelansicht': 'Card view',
    },
    'CARD': {
        'Phase': 'Stage',
        'Stufe': 'Level',
        'Erstellt': 'Created:',
        'Geändert': 'Changed:',
        'Archiviert': 'Archived',
        'WARNING1': 'Achtung: Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'WARNING2': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.'
    },
    'ROW': {
        'Phase': 'Stage',
        'Stufe': 'Level',
        'Erstellt': 'Created',
        'Geändert': 'Changed',
        'Archiviert': 'Archived',
        'Korrekturen': 'Corrections',
        'Druckfreigabe': 'Druckfreigabe',
        'Öffnen': 'Open',
        'Abbrechen': 'Abort',
        'Archivieren': 'Archive',
        'Reaktivieren': 'Reactivate',
        'Projekt wirklich archivieren': 'Sure to archive project {{name}}',
        'Projekt privat archivieren': "Projekt als 'privat' archivieren.",
        'Privat projekt info': 'Das Projekt kann im Archiv nur von zugewiesenen Benutzer gefunden werden',
        'WARNING1': 'Achtung: Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'WARNING2': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.'
    },
    'PROJECTCARD': {
        'Korrekturstufe(n)': 'Correctionlevel(s)',
        'Reaktivieren': 'Reactivate',
        'Archivieren': 'Archive',
        'Löschen': 'Delete',
        'Einstellungen': 'Settings',
    },
    'CORRECTIONLEVEL': {
        'Neue Korrekturstufe': 'New correction level',
        'Projekteinstellungen': 'Project settings',
        'Öffnen': 'Open',
        'PDF herunterladen': 'Download PDF',
        'Abbrechen': 'Abort',
        'Archivieren': 'Archive',
        'archiviert': 'archived',
        'Archiviert': 'Archived',
        'Reaktivieren': 'Reactivate',
        'Löschen': 'Delete correction level',
        'Projekt Archivieren': 'Projekt {{name}} wirklich archivieren?',
        'Projekt privat archivieren': "Projekt als 'privat' archivieren",
        'Projekt privat archivieren info': 'Das Projekt kann im Archiv nur von zugewiesenen Benutzer gefunden werden',
        'Korrekturstufe löschen': 'Korrekturstufe {{name}} wirklich löschen?',
        'Neues Projekt': 'Create new project based on current',
        'Alle Korrekturen': 'Alle Korrekturen müssen in weniger als [X] Stunden abgeschlossen werden.',
        'Bitte schließen': 'Bitte schließen Sie die Korrekturen innerhalb der nächsten [X] Tage ab.',
        'Aktuelle Korrekturstufe': 'Current correction level',
        'Druckfreigabe': 'Print approval on',
        'Stufe': 'Level',
        'Phase': 'Stage',
        'Erstellt': 'Created',
        'Geändert': 'Changed',
        'Korrekturen': 'Corrections',
        'Korrekturen insgesammt': 'All corrections',
        'Korrekturen warten': 'Corrections waiting to get qualified',
        'Korrekturen noch offen': 'Open corrections',
        'Korrekturen abgewiesen': 'Corrections rejected',
        'Korrekturen abgeschlossen': 'Corrections done',
        'Termin für Korrekturen': 'Corrections deadline',
        'Keine Korrekturstufe': 'No correction level created'
    },
    'TAGS': {
        'Schlagworte': 'Tags',
        'Schlagworte ausbleden': 'Hide tags',
        'Schlagworte anzeigen': 'Show tags',
    },
    'CORRECTIONLEVELS': {
        'vorherige Korrekturstufen': 'previous correction levels',
        'Korrekturen': 'Corrections',
        'Korrekturen insgesammt': 'corrections in total',
        'Korrekturen warten': 'Corrections waiting to get qualified',
        'Korrekturen noch offen': 'Open corrections',
        'Korrekturen abgewiesen': 'Corrections rejected',
        'Korrekturen abgeschlossen': 'Corrections done',
        'Druckfreigabe am': 'Print approval on',
        'Öffnen': 'Open',
        'PDF herunterladen': 'Donwload pdf',
        'Stufe': 'Level',
        'Abgeschlossen': 'Finished',
    },
    'ATTENDEES': {
        'Teilnehmer': 'Attendees',
        'Übernehmen': 'add',
    },
    'PAGES': {
        'Kein Status': 'No state',
        'in Bearbeitung': 'in progress',
        'Abgeschlossen': 'finished',
        'Seite bei keinem Kontrolleur abgeschlossen':'Page completed by no controller',
        'Seite bei': 'Page completed by',
        'von': 'of',
        'Kontrolleuren abgeschlossen': 'controllers',
        'Seite': 'Page',
    },
    'CORRECTIONLEVEL_DETAILS': {
        'Kein Status': 'No state',
        'in Bearbeitung': 'in progress',
        'Abgeschlossen': 'finished',
        'Korrekturübersicht': 'List of corrections',
        'Korrekturstufenfreigabe widerrufen': 'Korrekturstufenfreigabe widerrufen',
        'Korrekturstufe freigeben': 'Korrekturstufe freigeben',
        'Termin für Korrekturen': 'Deadline for corrections'
    },
    'DEADLINE': {
        'Termin für Korrekturen setzen': 'Set date for corrections deadline',
        'Abbrechen': 'Abort',
        'Übernehmen': 'Apply',
        'Termin für Korrekturen wurde geändert': 'Deadline saved',
    },
    'USERSTATES': {
        'Freigegeben': 'Locked, all pages finished',
        'in Bearbeitung 1': 'in progress, {{name}} pages finished',
        'in Bearbeitung 2': 'in progress, no pages finished',
        'Schließen': 'Close',
        'Alle Seiten abschließen': 'Finish all pages',
        'Übersicht Benutzerstatus': 'List of user states',
        'Kontrolleur': 'Controller',
        'Status': 'State',
        'Gesamt': 'all',
        'Angenommen': 'accepted',
        'Abgelehnt': 'rejected',
        'Qualifiziert': 'qualified',
    },
    'EDITOR_COMMENTS': {
        'Kommentare': 'Comments',
        'Korrektur akzeptiert': 'Correction accepted',
        'Korrektur abgelehnt': 'Correction rejected',
        'Korrektur noch nicht qualifiziert': 'Correction not yet qualified',
        'Korrektur offen': 'Correction still to do',
        'Korrektur erledigt': 'Correction done',
        'Korrektur hervorheben': 'Highlight correction',
        'Korrektur löschen': 'Delete correction',
        'Auf Korrektur antworten': 'Reply on correction',
        'Marker bearbeiten': 'Edit marker',
        'Als erledigt markieren': 'Mark as done',
        'Als offen markieren': 'Mark as open',
        'Qualifizieren': 'Qualify',
        'Ablehnen': 'Reject',
        'Korrekturen': 'Corrections',
        'Qualifiziert': 'Qualified',
        'Abgelehnt': 'Rejected',
        'Offen': 'Open',
        'Erledigt': 'Done',
        'Abbrechen': 'Abort',
        'Korrektur wirklich löschen': 'Sure to delete correction with all comments?',
    },
    'EDITOR_PAGES': {
        'Seiten': 'Pages',
        'Seite': 'Page',
        'Kein Status': 'No state',
        'in Bearbeitung': 'in progress',
        'Abgeschlossen': 'done',
    },
    'EDITOR_MENU': {
        'Vergleichsansicht': 'Compare view',
        'Standardansicht': 'Default view',
        'Kontrolleure benachrichtigen': 'Message controllers',
        'Werkzeuge u. Aktionen': 'Tools and actions',
        'Korrekturphase wechseln': 'Switch correction stage',
        'Abbrechen': 'Abort',
        'Seite abschließen': 'Lock page',
        'Seite wirklich abschließen': 'Sure to lock page? Action cannot be undone.',
        'Seite reaktivieren': 'Reactivate page',
        'OK marker': 'Set OK marker',
        'Currency marker': 'Set currency marker',
        'Text marker': 'Set text marker',
        'Area marker': 'Set area marker',
    },
    'EDITOR_CONTEXTMENU': {
        'Bearbeiten': 'Edit',
        'Löschen': 'Delete',
        'Abbrechen': 'Abort',
        'Korrektur löschen': 'Delete correction',
        'Korrektur wirklich löschen': 'Sure to delete correction with all comments?',
    },
    'EDITOR_CORRECTION': {
        'Korrektur bearbeiten': 'Edit correction',
        'Was soll korrigert werden': 'What should be corrected?',
        'Nachricht hinzufügen': 'Add message...',
        'Anhang': 'Attachment',
        'Datei vom Computer': 'Local file',
        'Abbrechen': 'Abort',
        'bestätigen': 'Confirm',
        'Durchsuchen': 'Search'
    },
    'EDITOR_ADDCOMMENT': {
        'Kommentar': 'Comment',
        'Neuer Kommentar': 'New comment',
        'Anhang': 'Attachment',
        'Datei vom Computer': 'Local file',
        'Abbrechen': 'Abort',
        'Übernehmen': 'Confirm',
        'Durchsuchen': 'Search'
    },
    'EDITOR_COMPARE': {
        'Vergleichsansicht': 'Compare mode',
        'Projekt zum Vergleichen auswählen': 'Select project to compare',
        'Projekt': 'Project',
        'Korrekturstufe': 'Correctionlevel',
        'erste Seite': 'first page',
        'vorherige Seite': 'previous page',
        'nächste Seite': 'next page',
        'letzte Seite': 'last page',
        'Schließen': 'Close',
        'Vergleichen': 'Compare',
        'Vergleichsoptionen': 'Comparing options'
    },



    'NEW': {
        'Projekt anlegen': 'Create project',
        'Projektname': 'Project name',
        'Schlagworte': 'Tags',
        'Schlagwort hinzufügen': 'Add tag',
        'Doppelseitensplit': 'Page split',
        'Schließen': 'Close',
        'Anlegen': 'Create'
    },
    'EDIT': {
        'Projekt bearbeiten': 'Edit project',
        'Projektname': 'Project name',
        'Schlagworte': 'Tags',
        'Schlagwort hinzufügen': 'Add tag',
        'Doppelseitensplit': 'Page split',
        'Schließen': 'Close',
        'Speichern': 'Save'
    }
};

export const en_user = {
    'Welcome to React': 'Welcome to React and react-i18next',
    'BASICS': {
        'MESSAGES': {
            'Username can only consists of letters (en), numbers and underscore' : 'Username can only consists of letters (en), numbers and underscore',
            'Username must consinst of at least three characters' : 'Username must consinst of at least three characters',
            'Username not available' : 'Username not available',
            'Username is empty' : 'Username is empty',
            'Email is empty' :  'E-Mail-Adresse darf nicht leer sein',
            'Invalid email address' :  'Ungültige E-Mail-Adresse',
            'Password must contain a special character' :  'Passwort muss mindestens ein Sonderzeichen enthalten',
            'Password must consist of at least 8 characters' :  'Passwort muss aus mindestens acht Zeichen bestehen',
            'Password must contain uppercase and lowercase characters' :  'Passwort muss Groß- und Kleinbuchstaben enthalten',
            'Password must contain a number' :  'Passwort muss mindestens eine Ziffer enthalten',
            'Passwords do not match' :  'Passworte stimmen nicht überein',
            'Password is empty' :  'Passwort darf nicht leer sein',
        },
        'LABELS': {
            'Username' : 'Username',
            'E-Mail' : 'eMail',
            'Password' : 'Password',
            'Repeat Password' : 'Repeat Password',
            'Active': 'Active',
            'Language' : 'Language',
        },
    },
    'RESETPW': {
        'LABELS': {
            'Passwort setzen' : 'Set password',
            'Schließen' : 'Close',
            'Repeat Password' : 'Repeat Passwort',
            'Password' : 'Password',
            'Username' : 'Username',
            'Domain' : 'Domain',
        },
    },
    'DETAILS': {
        'LABELS': {
            'forename' : 'Forename',
            'surname' : 'Surname',
            'company' : 'Company',
            'department' : 'Department',
        },
    },
    'ASSIGNMENTS': {
        'LABELS': {
            'Type' : 'Type',
            'Select role' : 'Select role',
            'Teams' : 'Teams',
            'Select team' : 'Select team',
            'Default Role': 'Default Role',
            'Select type' : 'Select type',
        }
    },
    'SETTINGS': {
        'LABELS': {
            'E-Mail Benachrichtigungen': 'eMail notifications',
            'interne Benachrichtigungen': 'internal notifications',
            'Kommentare automatisch aufklappen': 'expand comments automatically',
        }
    },
    'PROJECTS': {
        'LABELS': {
            'Eigene Projekte': 'Own projects',
            'Benutzer zu vorhandenen Projekte hinzufügen': 'Add user to existing projects',
            'Rolle wählen': 'Select role',
        },
        'BUTTONS' : {
            'zu Projekt hinzufügen' : 'add to project',
            'hinzufügen': 'add',
            'abbrechen': 'abort'
        }
    },
    'EDIT': {
        'MESSAGES': {
            'Remove user' : 'Sure to remove user {{name}}?',
            'Remove user info': 'User {{name}} will be removed from all teams and projects'
        },
        'LABELS': {
            'Edit User': 'Edit user {{name}}',
            
        },
        'BUTTONS' : {
            'Abort' : 'Abort',
            'Remove' : 'Remove',
            'Save' : 'Save',
            'Close' : 'Close',
        }
    },
    'NEW': {
        'LABELS': {
            'Benutzer anlegen' : 'Add new user',
        },
        'BUTTONS': {
            'Abort' : 'Abort',
            'Create' : 'Create',
        }
    },
    'LIST': {
        'LABELS': {
            'User list': 'User list',
            'Search' : 'Search',
            'Additional teams:': 'Additional teams:',
            'Username' : 'Username',
            'Full name' : 'Full name',
            'Last login' : 'Last login',
            'E-Mail' : 'eMail',
            'Company' : 'Company',
            'Department' : 'Department',
            'forename' : 'forename',
            'surname' : 'surname',
            'company' : 'company',
            'department' : 'department',
            'Type' : 'Type',
            'Teams' : 'Teams',
        },
        'BUTTONS': {
            'Close': 'Close',
            'Add user': 'Add user'
        }
    },
    'LOSTPW': {
        'Zugangsdaten vergessen': 'Forgot your login?',
        'Username': 'Username',
        'Oder': 'or',
        'E-Mail': 'eMail',
        'Vorname': 'Forname',
        'Nachname': 'Surname',
        'Schließen': 'Close',
        'Passwort zurücksetzen': 'Reset password',
    }
};

export const en_archive = {
    'PROJECTS': {
        'Projektübersicht': 'Projects',
        'Archiv': 'Archive',
    },
}

export const en_dashboard = {
    'LABELS': {
        'Kunden' : 'Customers',
        'Neuigkeiten': 'News',
        'Als gelesen markieren': 'Mark as read',
        'Als ungelesen markieren': 'Mark as unread',
        'Aktualisieren': 'Refresh'
    },
    'BUTTONS': {
        'Kundenliste ausblenden' : 'Hide customers',
        'Kundenliste einblenden': 'Show customers',
        'Neuigkeiten ausblenden' : 'Hide news',
        'Neuigkeiten einblenden': 'Show News',
    },
    'MENU': {
        'Abmelden': 'Logout',
        'System': 'System',
        'Profil': 'Profile',
        'Archiv': 'Archive',
        'Dashboard': 'Dashboard',
        'Seite': 'Page'
    },
    'SYSTEM': {
        'Systemeinstellungen': 'System settings',
        'Manage your preferences': 'Manage your preferences',
        'Einstellungen': 'Settings',
        'Benutzerliste': 'List of users',
        'Benutzer verwalten und anlegen': 'Create, edit, or remove list of users.',
        'Teams': 'Teams',
        'Teams verwalten und anlegen': 'Manage teams',
        'Rollen': 'Roles',
        'Rollen verwalten und anlegen': 'Manage roles',
        'Schlagworte': 'Tags',
        'Schlagworte verwalten und anlegen': 'Manage tags',
        'Change log': 'Change log',
        'aktuelle Version': 'current version',
        'Textvorlagen': 'Text templates',
        'Textvorlagen verwalten und anlegen': 'Create, edit and remove text templates'
    },

    'TEXTTEMPLATES': {
        'Textvorlagen': 'Text templates',
        'Name der Textvorlage': 'Name of the template',
        'Abbrechen': 'Abort',
        'Löschen': 'Delete',
        "Textvorlage '": "Sure to delete template '",  
        "' wirklich löschen?": "' ?",
        'neuer Vorlagentext': 'new template text',
        'Schließen': 'Close',
        'Hinzufügen': 'Add'
    },

    MULTIMESSAGE: {
        'Kontrolleure benachrichtigen': 'Notifiy correctors',
        'Nachricht': 'Message',
        'Nachricht hinzufügen': 'Enter message',
        'Textvorlagen': 'Text templates',
        'Übernehmen': 'Apply template',
        'Empfängereinschränkungen': 'Receiver restrictions',
        'Nur Kontrolleure benachrichtigen, die noch nicht abgeschlossen haben': 'Notify only correctors who not yet locked all pages',
        'Kontrolleure': 'Correctors',
        'Schließen': 'Close',
        'Benachrichtigen': 'Notify',
    }
};