// react base
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { setState, setCorrectionState, selectComment } from './comments.slice';
import { flashCorrectionMarker, selectedCorrection, selectCorrection, correctionMode, corrections, deleteCorrection } from './editor.slice';
import { showDialog } from './dialogAddComment.slice'
import { showCorrectionDialog } from './dialogCorrection.slice';
import { selectUser } from '../../login/login.slice';
import { dataProject } from '../corrector.slice';
import { selectConfig } from '../../../WECorrect.slice';
import { showConfirm } from '../../confirm/confirm.slice';

// Permissions
import WECPermissionHandler from '../../toolbox/permission_handler';

// semantic ui
import { Container, Comment, Header, Popup, Icon, Divider, Button, Label } from 'semantic-ui-react';

// Styles
import './comments.css';





/**
 * @param {String} mode
 * @param {Integer} correctionKey
 * @param {Object} correction 
 * @param {Boolean} correctionMode
 * @param {Object} comment
 * @param {Object} user 
 * @param {Integer} selected
 * @param {Boolean} readOnly
 * @param {Integer} size
 * @param {Boolean} permissionEdit 
 * @param {Boolean} permissionQualyfy
 * @param {Boolean} permissionDone
 * @param {Boolean} permissionComment
 * @param {Boolean} openReplies
 * @returns 
 */
const WECCorrectorEditorCommentsComment = ({mode, correctionKey, correction, comment, user, correctionMode, selected, readOnly, size, permissionEdit, permissionQualyfy, permissionDone, permissionComment, openReplies}) => {
  const dispatch = useDispatch();
  //const [ openReplies, setOpenReplies] = useState(false);

  // translation
  const { t } = useTranslation('corrector');
  
  // empty
  if (typeof comment === 'undefined') {
    return (<></>);
  }

  // selected
  let className = '';
  if (selected === correction.id) {
    className = 'comment_selected'
  }

  // Attachment
  let attachment = (<></>);
  if (typeof comment.attachment !== 'undefined' && typeof comment.attachment.filename !== 'undefined') {
    attachment = (<Comment.Text><Icon link name="attach" onClick={()=>{window.open('/media/uploads/'+correction.comment.attachment.filename, '_blank');}} /></Comment.Text>)
  }

  // Replies
  let replies = <></>;
  if (typeof comment.comments !== 'undefined' && comment.comments.length) {

    if (openReplies || (typeof user.settings !== 'undefined' && typeof user.settings.expandComments !== 'undefined' && user.settings.expandComments.value === '1')) {
      replies = comment.comments.map((rcomment, ii) => {
        let rattachment = (<></>);
        if (typeof rcomment.attachment !== 'undefined' && typeof rcomment.attachment.filename !== 'undefined') {
          rattachment = (<Comment.Text><Icon link name="attach" onClick={()=>{window.open('/media/uploads/'+rcomment.attachment.filename, '_blank');}} /></Comment.Text>)
        }
        return (
          <Comment key={ii} commentProps={rcomment} onClick={()=>{/*setOpenReplies(false)*/}}>
            <Comment.Content>
              <Comment.Author as='a'>{rcomment.user}</Comment.Author>
              {size === 1 && (<Comment.Metadata><Icon name='info circle' title={rcomment.creation} /></Comment.Metadata>)}
              {size !== 1 && (<Comment.Metadata><span>{rcomment.creation}</span></Comment.Metadata>)}
              <Comment.Text>{rcomment.content}</Comment.Text>
              {rattachment}
            </Comment.Content>
          </Comment>
        );
      })
    } else {
      replies = <Comment className='repliesClosed' onClick={()=>{/*setOpenReplies(true)*/}}><Comment.Metadata>{comment.comments.length} {t('corrector:EDITOR_COMMENTS.Kommentare')}</Comment.Metadata></Comment>
    }
    replies = (<Comment.Group size='mini' className='replies'>{replies}</Comment.Group>);
  }

  // state
  let stateClass = null;
  let stateTitle = '';
  switch (correction.state) {
    case 'A': stateClass = 'accepted'; stateTitle=t('corrector:EDITOR_COMMENTS.Korrektur akzeptiert'); break;
    case 'R': stateClass = 'rejected'; stateTitle=t('corrector:EDITOR_COMMENTS.Korrektur abgelehnt'); break;    
    default: stateClass = 'nostate'; stateTitle=t('corrector:EDITOR_COMMENTS.Korrektur noch nicht qualifiziert'); 
  }
  
  return (
    <Comment
      id = {'comment_' + correction.id} 
      className = {className} 
      commentProps = {comment} 
      onClick = {()=>{
        dispatch(selectComment(correction.id));
        dispatch(selectCorrection(correction.id));
        dispatch(flashCorrectionMarker(correction.cnt - 1));
        const canvas = mode !== 'compare' ? document.querySelector('.corrector_editor_canvas') : document.querySelector('.corrector_editor_canvasl');
        canvas.scrollTo({
          top: ( correction.marker.y - 200),
          left: ( correction.marker.x - 200),
          behavior: "smooth"
        });
      }}>
      <Comment.Content>
        <Comment.Author as='a'>{comment.user}</Comment.Author>
        {size === 1 && (<Comment.Metadata><Icon name='info circle' title={comment.creation} /></Comment.Metadata>)}
        {size !== 1 && (<Comment.Metadata><span>{comment.creation}</span></Comment.Metadata>)}
        <Comment.Metadata className='state'><div className={stateClass} title={stateTitle}></div></Comment.Metadata>
        <Comment.Metadata className='state'>
          {(typeof correction.userstate === 'undefined' || correction.userstate.state === 'open') && (<Icon size="large" title={t('corrector:EDITOR_COMMENTS.Korrektur offen')} name='circle outline'/>)}
          {typeof correction.userstate !== 'undefined' && correction.userstate.state === 'done' && (<Icon size="large" title={t('corrector:EDITOR_COMMENTS.Korrektur erledigt')} link name='check circle outline'/>)}
        </Comment.Metadata>
        <Comment.Text>{comment.content}</Comment.Text>
        {attachment}
        {correctionMode && (
          <React.Fragment>
            <Comment.Actions>
              {permissionEdit && (<Icon link name='trash' title={t('corrector:EDITOR_COMMENTS.Korrektur löschen')} onClick={()=>{
                dispatch(showConfirm({
                  cancelButton: t('corrector:EDITOR_COMMENTS.Abbrechen'),
                  confirmButton: t('corrector:EDITOR_COMMENTS.Korrektur löschen'),
                  message: t('corrector:EDITOR_COMMENTS.Korrektur wirklich löschen'),
                  onConfirm: ()=>dispatch(deleteCorrection(correction))
                }))
              }} />)}
              {permissionComment && (<Popup content={t('corrector:EDITOR_COMMENTS.Auf Korrektur antworten')} trigger={<Icon link name='comment' onClick={()=>dispatch(showDialog(correction.domain))} />} />)}
              {permissionEdit && mode !== 'compare' && (<Popup content={t('corrector:EDITOR_COMMENTS.Marker bearbeiten')} trigger={<Icon link name='pencil' onClick={()=>dispatch(showCorrectionDialog({domain: correction.domain, correction: correction}))} />} />)}

              {!readOnly && size === 1 && (
                <React.Fragment>
                  {(typeof correction.userstate === 'undefined' || correction.userstate.state === 'open') && permissionDone && (<Button icon primary size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Als erledigt markieren')} onClick={()=>dispatch(setCorrectionState(correction.domain, correction.id, 'done'))}><Icon name='check circle outline' /></Button>)}
                  {typeof correction.userstate !== 'undefined' && correction.userstate.state === 'done' && permissionDone && (<Button icon size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Als offen markieren')} onClick={()=>dispatch(setCorrectionState(correction.domain, correction.id, 'open'))}><Icon name='circle outline' /></Button>)}
                </React.Fragment>
              )}
              {!readOnly && size !== 1 && (
                <React.Fragment>
                  {(typeof correction.userstate === 'undefined' || correction.userstate.state === 'open') && permissionDone && (<Button icon primary size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Als erledigt markieren')} onClick={()=>dispatch(setCorrectionState(correction.domain, correction.id, 'done'))}>Erledigt</Button>)}
                  {typeof correction.userstate !== 'undefined' && correction.userstate.state === 'done' && permissionDone && (<Button icon size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Als offen markieren')} onClick={()=>dispatch(setCorrectionState(correction.domain, correction.id, 'open'))}>Offen</Button>)}
                </React.Fragment>
              )}

              {!readOnly && size === 1 && (
                <React.Fragment>
                  {correction.state !== 'A' && permissionQualyfy && (<Button icon primary size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Qualifizieren')} onClick={()=>dispatch(setState({domain: correction.domain, id: correction.id, key: correctionKey, state: 'accept'}))}><Icon name='thumbs up' /></Button>)}
                  {correction.state !== 'R' && permissionQualyfy && (<Button icon size='mini' floated='right' title={t('corrector:EDITOR_COMMENTS.Ablehnen')} onClick={()=>dispatch(setState({domain: correction.domain, id: correction.id, key: correctionKey, state: 'reject'}))}><Icon name='thumbs down' /></Button>)}      
                </React.Fragment>
              )}
              {!readOnly && size !== 1 && (
                <React.Fragment>
                  {correction.state !== 'A' && permissionQualyfy && (<Button primary size='mini' floated='right' onClick={()=>dispatch(setState({domain: correction.domain, id: correction.id, key: correctionKey, state: 'accept'}))}>{t('corrector:EDITOR_COMMENTS.Qualifizieren')}</Button>)}
                  {correction.state !== 'R' && permissionQualyfy && (<Button size='mini' floated='right' onClick={()=>dispatch(setState({domain: correction.domain, id: correction.id, key: correctionKey, state: 'reject'}))}>{t('corrector:EDITOR_COMMENTS.Ablehnen')}</Button>)}
                </React.Fragment>
              )}
            </Comment.Actions>
          </React.Fragment>
        )}
      </Comment.Content>
      {replies}
    </Comment>
  );
}



/**
 * 
 * @param {Boolean} readOnly
 * @param {Integer} size
 * @param {String} mode
 * @returns 
 */
const WECCorrectorEditorComments = ({readOnly, size, mode}) => {
  // local state
  const [filter, setFilter] = useState(null);

  // global state
  const e_selectedCorrection = useSelector(selectedCorrection);
  const e_corrections = useSelector(corrections);
  const e_correctionMode = useSelector(correctionMode);
  const l_selectUser = useSelector(selectUser);
  const c_project = useSelector(dataProject);
  const config = useSelector(selectConfig);

  // translation
  const { t } = useTranslation('corrector');

  // Permissions
  let permissionHandler = new WECPermissionHandler();
  const permission_PERM_EDT_05 = permissionHandler.hasPermission(l_selectUser, 'PERM_EDT_05', c_project.attendees, config[config.domain].user.roles) && l_selectUser.type !== 4;
  const permission_PERM_EDT_08 = permissionHandler.hasPermission(l_selectUser, 'PERM_EDT_08', c_project.attendees, config[config.domain].user.roles) && (l_selectUser.type === 1 || l_selectUser.type === 3);
  const permission_PERM_EDT_09 = permissionHandler.hasPermission(l_selectUser, 'PERM_EDT_09', c_project.attendees, config[config.domain].user.roles) && (l_selectUser.type === 1 || l_selectUser.type === 2);
  const permission_PERM_EDT_10 = permissionHandler.hasPermission(l_selectUser, 'PERM_EDT_10', c_project.attendees, config[config.domain].user.roles) && l_selectUser.type !== 4;

  // Fallback qualify permission if no customer admin
  const roles = config[config.domain].user.roles;
  const users = config[config.domain].user.users;
  let found_customer_admin = false;
  let permission_PERM_EDT_08_FALLBACK = false;
  c_project.attendees.forEach(attendee => {
    const roleId = attendee.role;
    const userId = attendee.userid;
    users.forEach(_user => {
      if (_user.id === userId && _user.type === 3) {
        for (const key in roles) {
          if (Object.hasOwnProperty.call(roles, key)) {
            const _role = roles[key];
            if (''+_role.id === ''+roleId && _role.type === 'S_ADMIN') {
              found_customer_admin = true;
            }   
          }
        }
      }
    });
  });
  if (!found_customer_admin && permissionHandler.hasPermission(l_selectUser, 'PERM_EDT_08', c_project.attendees, config[config.domain].user.roles) && l_selectUser.type === 2) {
    permission_PERM_EDT_08_FALLBACK = true
  }

  // check if current user has role S_GRAPHIC
  let roleS_GRAPHIC_id = null;
  for (const key in roles) {
    if (Object.hasOwnProperty.call(roles, key)) {
      const _role = roles[key];
      if (_role.type === 'S_GRAPHIC') {
        roleS_GRAPHIC_id = _role.id;
      }   
    }
  }
  let userIsGraphic = false;
  c_project.attendees.forEach(attendee => {
    if (attendee.userid === l_selectUser.id && attendee.role === roleS_GRAPHIC_id) {
      userIsGraphic = true;
    }
  });

  //
  let f_corrections = null;
  let _corrections = {
    ACCEPTED: [],
    REJECTED: [],
    OPEN: [],
    DONE: []
  };
  let cnt_f_corrections = {
    ACCEPTED: 0,
    REJECTED: 0,
    OPEN: 0,
    DONE: 0
  }

  // apply filter
  e_corrections.forEach(correction => {
    if (correction.state === 'A') {
      _corrections.ACCEPTED.push(correction)
      cnt_f_corrections.ACCEPTED++;
    }
    if (correction.state === 'R') {
      if (!userIsGraphic) {
        _corrections.REJECTED.push(correction)
        cnt_f_corrections.REJECTED++;
      }
    }
    if (typeof correction.userstate !== 'undefined' && typeof correction.userstate.state !== 'undefined' && correction.userstate.state === 'done') {
      _corrections.DONE.push(correction)
      cnt_f_corrections.DONE++;
    }
    if (typeof correction.userstate === 'undefined' || (typeof correction.userstate !== 'undefined' && typeof correction.userstate.state !== 'undefined' && correction.userstate.state === 'open')) {
      _corrections.OPEN.push(correction)
      cnt_f_corrections.OPEN++;
    }
  });
  switch(filter) {
    case 'ACCEPTED': f_corrections = _corrections.ACCEPTED; break;
    case 'REJECTED': f_corrections = _corrections.REJECTED; break;
    case 'OPEN': f_corrections = _corrections.OPEN; break;
    case 'DONE': f_corrections = _corrections.DONE; break;
    default: f_corrections = e_corrections;
  }

  return (
    <React.Fragment>
      <Header as='h3'>{t('corrector:EDITOR_COMMENTS.Korrekturen')}</Header>
      <Label.Group>
        {size === 2 && (
          <>
            <Label color={filter === 'ACCEPTED' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'ACCEPTED' ? null : 'ACCEPTED')}>
            {t('corrector:EDITOR_COMMENTS.Qualifiziert')}
              <Label.Detail>{cnt_f_corrections.ACCEPTED}</Label.Detail>
            </Label>
            {!userIsGraphic && (
              <Label color={filter === 'REJECTED' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'REJECTED' ? null : 'REJECTED')}>
              {t('corrector:EDITOR_COMMENTS.Abgelehnt')}
                <Label.Detail>{cnt_f_corrections.REJECTED}</Label.Detail>
              </Label>
            )}
            <Label color={filter === 'OPEN' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'OPEN' ? null : 'OPEN')}>
            {t('corrector:EDITOR_COMMENTS.Offen')}
              <Label.Detail>{cnt_f_corrections.OPEN}</Label.Detail>
            </Label>
            <Label color={filter === 'DONE' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'DONE' ? null : 'DONE')}>
            {t('corrector:EDITOR_COMMENTS.Erledigt')}
              <Label.Detail>{cnt_f_corrections.DONE}</Label.Detail>
            </Label>
          </>
        )}
        {size === 1 && (
          <>
            <Label title={t('corrector:EDITOR_COMMENTS.Qualifiziert')} color={filter === 'ACCEPTED' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'ACCEPTED' ? null : 'ACCEPTED')}>
              <Icon name='thumbs up' />
            </Label>
            {!userIsGraphic && (
              <Label title={t('corrector:EDITOR_COMMENTS.Abgelehnt')} color={filter === 'REJECTED' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'REJECTED' ? null : 'REJECTED')}>
                <Icon name='thumbs down' />
              </Label>
            )}
            <Label title={t('corrector:EDITOR_COMMENTS.Offen')} color={filter === 'OPEN' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'OPEN' ? null : 'OPEN')}>
              <Icon name='square outline' />
            </Label>
            <Label title={t('corrector:EDITOR_COMMENTS.Erledigt')} color={filter === 'DONE' ? 'blue' : ''} as='a' onClick={()=>setFilter(filter === 'DONE' ? null : 'DONE')}>
              <Icon name='check square outline' />
            </Label>
          </>
        )}
      </Label.Group>
      <Divider style={{marginRight: "0 !important"}} />
      <Container className='commentslist'>
        <Comment.Group threaded>
        {f_corrections.map((correction, i) => {

          // User role S_GRAFIC should not see rejected corrections
          if (userIsGraphic && correction.state === 'R' ) {
            return <></>;
          }

          return (
            <React.Fragment>
              <WECCorrectorEditorCommentsComment
                key = {i}
                mode = {mode}
                correctionKey = {i}
                size = {size}
                readOnly = {readOnly}
                correction = {correction} 
                comment = {correction.comment} 
                user = {l_selectUser} 
                permissionEdit = {permission_PERM_EDT_05}
                permissionQualyfy = {permission_PERM_EDT_08 || permission_PERM_EDT_08_FALLBACK}
                permissionDone = {permission_PERM_EDT_09}
                permissionComment = {permission_PERM_EDT_10}
                correctionMode = {e_correctionMode}
                selected = {e_selectedCorrection} 
                openReplies = {e_selectedCorrection === correction.id || (typeof l_selectUser.settings.expandComments !== 'undefined' && l_selectUser.settings.expandComments.value === '1') ? true : false} />
              <Divider />
            </React.Fragment>
          );
        })}
        </Comment.Group>
      </Container>
    </React.Fragment>
  );
}

export default WECCorrectorEditorComments;