// react base
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Icon, Divider, List, Tab, Card } from 'semantic-ui-react';

// Redux
import { isVisible, hideSystem } from './system.slice';
import { showListUsers } from '../user/user.slice';
import { showTeams } from '../teams/teams.slice';
import { showRoles } from '../roles/roles.slice';
import { showTags } from '../tags/tags.slice';
import { showTextTemplates } from '../texttemplates/texttemplates.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './system.css';






/**
 *
 */
const WECSystem = () => {

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const userLoggedIn  = useSelector(selectUser);

    // translations
    const { t } = useTranslation('dashboard', 'common');

    // scolling 
    const scrollUpHandler = () => {
        let scrollerContent = document.getElementById('scrollerContent');
        if (typeof scrollerContent !== 'undefined' && scrollerContent !== null) {
            let scrollerContent2 = document.getElementById('scrollerContent').childNodes[0].childNodes[0].childNodes[0];
            scrollerContent2.scrollTo({
                top: 50,
                left: 0,
                behavior: "smooth"
            });
            //el.scrollTop
        }
    }

    const scrollDownHandler = () => {
        let scrollerContent = document.getElementById('scrollerContent');
        if (typeof scrollerContent !== 'undefined' && scrollerContent !== null) {
            let scrollerContent2 = document.getElementById('scrollerContent').childNodes[0].childNodes[0].childNodes[0];
            console.log(scrollerContent2.scrollTop);
            scrollerContent2.scrollTo({
                top: 150,
                left: 0,
                behavior: "smooth"
            });
        }
    }

  // autostart load projects
  useEffect(() => {
    let scollerUp = document.getElementById('scollerUp');
    let scollerDown = document.getElementById('scollerDown');
    let scrollerContent = document.getElementById('scrollerContent');
    if (typeof scrollerContent !== 'undefined' && scrollerContent !== null) {
        let scrollerContent2 = document.getElementById('scrollerContent').childNodes[0].childNodes[0].childNodes[0];
        if (typeof scrollerContent2 !== 'undefined' && scrollerContent2 !== null && typeof scollerUp !== 'undefined' && scollerUp !== null) {
            scrollerContent2.parentNode.insertBefore(scollerUp, scrollerContent2);
        }
        if (typeof scrollerContent2 !== 'undefined' && scrollerContent2 !== null && typeof scollerDown !== 'undefined' && scollerDown !== null) {
            scrollerContent2.parentNode.insertBefore(scollerDown, null);
        }
    }
  });

    // 
    if (!visible) {
        return <></>
    }
    
    // Release notes
    const releasePanes = [
        { menuItem: '2.2023.001', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Darstellugsproblem in Schlagwortverwaltung behoben</List.Item>
                    <List.Item>Fehler in Benachrichtigungsfunktion (Snackbar) behoben</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Weitere Übersetzungen nachgepflegt</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Dialog zum Verwalten von Textvorlagen in den Systemeinstellungen</List.Item>
                    <List.Item>Dialog zum Versenden von Multi-Messages um Textvorlagen erweitert</List.Item>
                    <List.Item>Dialog zum Versenden von Multi-Messages um Funcktion zur Empfänger-Einschränkung erweitert</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>14.12.2023</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.008', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem mit falscher angezeigter Seite im Vergleichsmodus behoben</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Kommentare zu den Korrekturen werden nun automatisch eingeblendet, wenn eine Korrektur selektiert wird</List.Item>
                    <List.Item>Doppelseitensplit bei Projektanlage nun standardmäßig aus</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Passwort-Vergessen-Dialog</List.Item>
                    <List.Item>Verschieben der Seite im Editor mit der Maus möglich</List.Item>
                    <List.Item>Aktuell geöffnetes Projekt wird in der Projektauswahl der Vergleichsansicht vorausgewählt</List.Item>
                    <List.Item>Schließen-Button zum Verlassen der Vergleichsansicht auch im Kopf der Vergleichsansicht</List.Item>
                    <List.Item>Aktuell geöffnetes Projekt wird in der Projektauswahl der Vergleichsansicht vorausgewählt</List.Item>
                    <List.Item>Verschieben der Seite im Editor mit der Maus möglich auch in der Vergleichsansicht möglich</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>06.04.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.007', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Button zum Widerrufen der Seitenfreigabe erscheint nicht in der breiten Werkzeugleisten-Ansicht</List.Item>
                    <List.Item>Speicherbutton in Projekteinstellungen-Dialog erscheint wenn, wenn ein User zum Projekt hinzugefügt wurde</List.Item>
                    <List.Item>Nach Änderung und Speicherung eines Markers und anschließendem Seitenwechseln und zurück, erscheint Save-Kontext-Menü nun nicht mehr</List.Item>
                    <List.Item>Rechteproblem in der Projektliste der Benutzerdetails behoben</List.Item>
                    <List.Item>Detailierte Auflistung der Korrekturstatuus korribiert</List.Item>
                    <List.Item>Filter in Korrekturenliste erscheint wieder</List.Item>
                    <List.Item>Kommentare verschwinden beim Seitenwechsel</List.Item>
                    <List.Item>Fehler beim Bearbeiten (Größe, Position) einer Korrektur behoben</List.Item>
                    <List.Item>Fehler in der Feinjustierung beim Anlegen  einer Korrektur behoben</List.Item>
                    <List.Item>Problem mit der Rechteverwaltung innerhalb von Projekten behoben</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Klick auf Vorschaubild in Projektdertails öffnet aktuelle Korrekturstufe</List.Item>
                    <List.Item>Weitere Übersetzungen nachgepflegt</List.Item>
                    <List.Item>Darstellung der Seiteninformationen in der Seitenübersicht überarbeitet</List.Item>
                    <List.Item>Benutzer mit der Rolle 'Graphik' bekommen keine abgewiesenen Korrekturen mehr angezeigt</List.Item>
                    <List.Item>Löschen Button anstelle des Highlight-Buttons</List.Item>
                    <List.Item>Cursor-Darstellung im Editor-Bereich verbessert</List.Item>
                    <List.Item>bei Neuladen der Webseite eingeloggt bleiben</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Löschen von Korrekturen</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>24.03.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.006', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>interner Projektleiter kann Korrekturen qualifizieren, wenn kein Kunden-Projektleiter existiert</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>25.02.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.005', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Fehler beim Anlegen einer neuen Korrekturstufe behoben</List.Item>
                    <List.Item>Rücksprung in die Seitenübersicht aus dem Editor heraus ist wieder möglich</List.Item>
                    <List.Item>Submenü (Werkzeugleiste) ist auch in der Vergleichsansicht wieder sichtbar</List.Item>
                    <List.Item>Repositionierung der Icons / Flächen wird nach Speicherung und Seitenwechsel wieder korrekt angezeigt</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>24.02.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.004', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Benutzerliste wird nun nach dem Anlegen eines neuen Benutzers automatisch aktualisiert</List.Item>
                    <List.Item>Benutzer können direkt nach dem Anlegen auch wieder gespeichert werden</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>21.02.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.003', render: () => (
            <Tab.Pane>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>weitere Lokalisierungen</List.Item>
                    <List.Item>Verbessertes Scrollverhalten in der Benutzerliste</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem bei Anlegen von neuen Benutzern, wenn eine konkrete Rolle ausgewählt ist</List.Item>
                    <List.Item>Problem mit fehlenden Sortierungsmarkierungen in der Benutzerliste behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>17.02.2022</Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.002', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Sprachewähler zum Wechseln der Systemsprache im Benutzerprofil</List.Item>
                    <List.Item>Lokalisierungen</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Ansicht Projektdetails überarbeitet</List.Item>
                    <List.Item>Platzhalter-Bilder in Projektliste überarbeitet</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem bei Teams ohne Benutzer behoben</List.Item>
                    <List.Item>Problem in der Teilnehmerliste behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    15.02.2022
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2022.001', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Mandanten-Auswahl für Agenturbenutzer</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem beim Bearbeiten der Benutzer behoben</List.Item>
                    <List.Item>Problem beim Login eines Benutzers mit fehlender Standad-Rolle behoben</List.Item>
                    </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    17.01.2022
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.011', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>E-Mail-Benachrichtigungen bei Statuswechsel (mit DeepLink zum Projekt)</List.Item>
                    <List.Item>Benutzertyp- und rollenbasierte Zugriffsrechte-Steuerung</List.Item>
                    <List.Item>Standard-Rolle für Benutzer</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Standard-Rolle wird nun pro Benutzer festgelegt und nicht mehr nur pro Team</List.Item>
                    <List.Item>Doppelseiten-Split kann in den Projekteinstellungen nur noch solange bearbeitet werden, wie noch keine Korrekturstufe hangelegt wurde</List.Item>
                    <List.Item>Thumbails in den verschiedenen Vorschau-Formaten verbessert</List.Item>
                    <List.Item>Initiale Zoomstufe wird nun auch beim Seitenwechsel im Editor neu berechnet</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Button zum Hinzufügen wird jetzt nur noch in Korrektuphase 'Umsetzung', bzw. wenn noch keine KS vorhanden ist, angezeigt</List.Item>
                    <List.Item>Falscher Rücksprung ins Dashboard beim Öffnen der Projekteinstellungen bei Prohjekten ohne Korrekturstufe behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    14.12.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.010', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Benutzer können nun entfernt werden</List.Item>
                    <List.Item>Entfernte Benutzer werde nun ebenfalls aus den jeweiligen Projekten und Teams entfernt</List.Item>
                    <List.Item>Entfernte Benutzer werden nun in den jeweiligen Listen ausgeblendet</List.Item>
                    <List.Item>Möglichkeit zum Löschen von Korrekturstufen</List.Item>
                    <List.Item>Seitenübersicht enthält bei Role Leitung eine Info über Fortschritt der Kontrolleure</List.Item>
                </List>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Es wird nun in feste und freie Rollen unterschieden</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Behebt einen Fehler in der Benutzerverwaltung beim Hinzufügen vo Benutzern zu vorhandenen Projekten</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    17.11.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.009', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Bei der Festlegung der Korrekturen-Deadline kann jetzt auch eine Uhrzeit gewählt werden</List.Item>
                    <List.Item>Korrekturtermin wird in Korrekturstufenübersicht angezeigt</List.Item>
                    <List.Item>Korrekturtermin-Warnung wird in Korrekturstufenübersicht angezeigt</List.Item>
                    <List.Item>Korrekturtermin-Warnung wird im Dashboard (Card-Ansicht) angezeigt</List.Item>
                    <List.Item>Korrekturtermin &amp; -Warnung wird im Dashboard (tabellarische Ansicht) angezeigt</List.Item>
                    <List.Item>Beim Wechsel zur Korrekturphase wird jetzt auch der Korrekturtermin-Dialog angezeigt</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    29.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.008', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Hinzufügen einer neuen Korrekturstufe jetzt nur noch in der Initialisierungphase möglich</List.Item>
                    <List.Item>Phasenwechsel aus Qualifizierungnur noch bei vollständig qualifizierten Korrekturen möglich</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>neue Korrekturen werden nun nicht mehr automatisch als akzeptiert qualifiziert beim Anlegen</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    12.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.007', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem mit der Aktualisierung der Korrekturenliste beim Seitenwechel nach Hinzufügen einer neuen Korrekt behoben</List.Item>
                    <List.Item>Einstellungsmöglichkeit bezüglich Doppelseiten-Split hat jetzt wieder greift jetzt wieder beim Erstellen einer neuen Korrekturstufe </List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    12.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.006', render: () => (
            <Tab.Pane>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem mit der Aktualisierung der Korrekturenliste beim Hinzufügen einer neuen Korrekt behoben</List.Item>
                    <List.Item>Problem mit der Füllung der Markierung nach anlegen einer neuen Korrekturen behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    06.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.005', render: () => (
            <Tab.Pane>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Darstellung des Submenüs im Editor bei Vergleichsansicht überarbeitet</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Initiale Zoomstufe orientiert sich jetzt an der Fenstergröße </List.Item>
                    <List.Item>Weitere Snackbar-Benachrichtigungen hinzugefügt </List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem mit fehlenden Tags in Projekten behoben</List.Item>
                    <List.Item>Problem mit Doppelseiten-Split-Wähler im Projektdialog behoben</List.Item>
                    <List.Item>Problem mit active-State von Projekten beim Speichern der Projektdetails behoben</List.Item>
                    <List.Item>Problem mit fehlender Korrekturstufen-Phase behoben</List.Item>
                    <List.Item>Problem mit der Aktualisierung der Korrekturenliste beim Hinzufügen einer neuen Korrekt behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    05.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.004', render: () => (
            <Tab.Pane>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Nutzer-Benachrichtigung auch in der Projektteilnehmerliste möglich</List.Item>
                    <List.Item>Nutzer-Benachrichtigung auch in der Benutzerliste (System) möglich</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem beim Speichern der Projektbenutzer behoben</List.Item>
                    <List.Item>Darstellugsproblem in der Projektteilnehmerliste behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    01.10.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.003', render: () => (
            <Tab.Pane>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Icons im Hauptmenü ausgetauscht</List.Item>
                    <List.Item>Icons im Dashboard/Archiv ausgetauscht</List.Item>
                    <List.Item>Toolbar-Icons im Editor ausgetauscht</List.Item>
                    <List.Item>Savig-Animation überarbeitet</List.Item>
                    <List.Item>Seitenübersicht im Editor überarbeitet</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Änderung der Darstellung der Projektliste im Dashboard wird in den Benutzerinstellungen gespeichert</List.Item>
                    <List.Item>Seitenstatus wird jetzt auch in der Seitenübersicht des Editors angezeigt</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Problem beim Speichern der Benutzereinstellungen behoben</List.Item>
                    <List.Item>Problem beim Auf- und Zuklappen der Korrekturkommentare behoben</List.Item>
                    <List.Item>Fehler bei der Benutzeranlage behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    30.09.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.002', render: () => (
            <Tab.Pane>
                <Header as='h4'>Verbessert</Header>
                <List bulleted>
                    <List.Item>Archivansicht überarbeitet</List.Item>
                    <List.Item>Tabellarische Ansicht der Projektliste überarbeitet</List.Item>
                    <List.Item>Systemdialog überarbeitet</List.Item>
                </List>
                <Header as='h4'>Neu hinzugekommen</Header>
                <List bulleted>
                    <List.Item>Projekte können privat archiviert werden</List.Item>
                    <List.Item>PDF-Download Buttons in der Projektansicht</List.Item>
                    <List.Item>Button Kopiervorlage - Zum Erstellen eines neuen Projektes auf Basis eines bestehenden Projekts</List.Item>
                </List>
                <Header as='h4'>Fehlerkorrektur</Header>
                <List bulleted>
                    <List.Item>Fehler bei Benutzeranlage behoben</List.Item>
                    <List.Item>Fehler bei Projektanlage behoben</List.Item>
                    <List.Item>Fehler bei der Benutzerzuweisung behoben</List.Item>
                </List>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    23.09.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
        { menuItem: '2.2021.001', render: () => (
            <Tab.Pane>
                <Header as='h4'>Erstinstallation</Header>
                <Header as='h4'>
                    Veröffentlicht
                    <Header.Subheader>
                    23.09.2021
                    </Header.Subheader>
                </Header>
            </Tab.Pane>
        )},
      ] 

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_01 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_01');
    const permission_PERM_SYS_04 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_04');
    const permission_PERM_SYS_07 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_07');
    const permission_PERM_SYS_10 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_10');
    const permission_PERM_SYS_13 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_13');
    

    return (
        <Modal
            open = {visible}
            className = 'wecsystem'
            size = 'large'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideSystem())}
        >
            <React.Fragment>
                <Modal.Content className='title'>
                    <Header as='h2'>
                        <Icon name='settings' />
                        <Header.Content>
                            {t('dashboard:SYSTEM.Systemeinstellungen')}
                            <Header.Subheader>{t('dashboard:SYSTEM.Manage your preferences')}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Modal.Content>
                <Modal.Content className='categories'>
                    <Modal.Content>
                        <Divider horizontal>{t('dashboard:SYSTEM.Einstellungen')}</Divider>
                    </Modal.Content>
                    <Card.Group itemsPerRow={5} style={{marginTop: '30px'}}>
                        {permission_PERM_SYS_01 && (
                            <Card 
                                image={'/media/icons/WE_correct_icon_benutzerliste.svg'}
                                header={t('dashboard:SYSTEM.Benutzerliste')}
                                description={t('dashboard:SYSTEM.Benutzer verwalten und anlegen')}
                                onClick={()=>dispatch(showListUsers())}
                            />
                        )}
                        {permission_PERM_SYS_04 && (
                            <Card 
                                image={'/media/icons/WE_correct_icon_teams.svg'}
                                header={t('dashboard:SYSTEM.Teams')}
                                description={t('dashboard:SYSTEM.Teams verwalten und anlegen')}
                                onClick={()=>dispatch(showTeams())}
                            />
                        )}
                        {permission_PERM_SYS_07 && (
                            <Card 
                                image={'/media/icons/WE_correct_icon_rollen.svg'}
                                header={t('dashboard:SYSTEM.Rollen')}
                                description={t('dashboard:SYSTEM.Rollen verwalten und anlegen')}
                                onClick={()=>dispatch(showRoles())}
                            />
                        )}
                        {permission_PERM_SYS_10 && (
                            <Card 
                                image={'/media/icons/WE_correct_icon_schlagworte.svg'}
                                header={t('dashboard:SYSTEM.Schlagworte')}
                                description={t('dashboard:SYSTEM.Schlagworte verwalten und anlegen')}
                                onClick={()=>dispatch(showTags())}
                            />
                        )}
                        {permission_PERM_SYS_13 && (
                            <Card 
                                image={'/media/icons/WE_correct_icon_vorlage-bearbeiten.svg'}
                                header={t('dashboard:SYSTEM.Textvorlagen')}
                                description={t('dashboard:SYSTEM.Textvorlagen verwalten und anlegen')}
                                onClick={()=>dispatch(showTextTemplates())}
                            />
                        )}
                    </Card.Group>
                </Modal.Content>
                <Modal.Content className='system'>
                    <Divider horizontal>{t('dashboard:SYSTEM.Change log')}</Divider>
                    <div id="scollerUp" onMouseOver={()=>scrollUpHandler()} onMouseOut={()=>{}}> &nbsp; </div>
                    <Tab id='scrollerContent' menu={{ fluid: true, vertical: true, tabular: true, secondary: true, pointing: true  }} panes={releasePanes} />
                    <div id="scollerDown" onMouseOver={()=>scrollDownHandler()} onMouseOut={()=>{}}> &nbsp; </div>
                </Modal.Content>
                <Modal.Content className='categories'>
                    <span>{t('dashboard:SYSTEM.aktuelle Version')}:  <b>2.2023.001</b></span>
                </Modal.Content>
            </React.Fragment>
        </Modal>
    );
}

export default WECSystem;