// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Table, Icon, Input, TextArea, Message } from 'semantic-ui-react';

// Redux
import { isVisible, hideTextTemplates, getTextTemplates, setTextTemplates, deleteTextTemplate, updateTextTemplate, createTextTemplate } from './texttemplates.slice';
import { selectConfig, selectBaseProtocol, selectBackendUrl } from '../../WECorrect.slice';
import { fetchGET } from "../toolbox/requestor.slice";
import { showConfirm } from "../confirm/confirm.slice";
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './texttemplates.css';




/**
 *
 */
const WECTextTemplates = () => {
    // local state
    const [textTemplateNew, setTextTemplateNew] = useState(null);
    const [textTemplateEdit, setTextTemplateEdit] = useState(null);

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const texttemplates = useSelector(getTextTemplates);
    const config = useSelector(selectConfig);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const backendhUrl = baseProtocol + '//' + useSelector(selectBackendUrl);
    const userLoggedIn  = useSelector(selectUser);

    // translations
    const { t } = useTranslation('dashboard', 'common');

    //
    useEffect(() => {
        if (visible && !texttemplates) {
            const options = { filter: { domain: config.domain}, sorting:{ name: 'ASC' } };
            dispatch(fetchGET(backendhUrl + '/api/latest/'+config.domain+'/texttemplates/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
                response => dispatch(setTextTemplates(response))
            );
        }
    })

    // 
    if (!visible) {
        return <></>
    }
    
    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_14 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_14') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);
    const permission_PERM_SYS_15 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_15') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    // list of available texttemplates
    let texttemplates_list = <></>;
    if (typeof texttemplates !== 'undefined' && texttemplates) {
        texttemplates_list = texttemplates.map((texttemplate) => {
            if (texttemplate.type === 'texttemplate') {
                if (textTemplateEdit !== null && typeof textTemplateEdit.id !== 'undefined' && textTemplateEdit.id !== null && textTemplateEdit.id === texttemplate.id) {
                    return (
                        <Table.Row>
                            <Table.Cell verticalAlign='top' collapsing>
                                <Input size='small' placeholder={t('dashboard:TEXTTEMPLATES.Name der Textvorlage')} value={textTemplateEdit.name} onChange={(e, {value})=>{
                                    let _textTemplate = Object.assign({}, textTemplateEdit);
                                    _textTemplate.name = value;
                                    setTextTemplateEdit(_textTemplate);
                                }} />    
                            </Table.Cell>
                            <Table.Cell verticalAlign='top' collapsing>
                                <Message style={{maxWidth: "600px", overflow: "auto"}}>
                                    <TextArea 
                                        className="no_border" 
                                        onChange={(e, {value})=>{
                                            let _textTemplate = Object.assign({}, textTemplateEdit);
                                            _textTemplate.text = value;
                                            setTextTemplateEdit(_textTemplate);
                                        }}
                                        style={{width: "565px", height: "150px"}}
                                    >{textTemplateEdit.text}</TextArea>
                                </Message>
                            </Table.Cell>
                            <Table.Cell verticalAlign='top' textAlign='right' collapsing >
                                {permission_PERM_SYS_15 && (
                                    <>
                                    <Button primary icon onClick={()=>{
                                        dispatch(updateTextTemplate(textTemplateEdit))
                                        setTextTemplateEdit(null)
                                    }}><Icon name='save' /></Button>
                                    <Button icon onClick={()=>setTextTemplateEdit(null)}><Icon name='times' /></Button>
                                    </>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    );
                }
                return (
                    <Table.Row>
                        <Table.Cell verticalAlign='top' collapsing>
                            <Input className="no_border" size='small' readOnly value={texttemplate.name} />
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' collapsing>
                            <Message style={{maxWidth: "600px", overflow: "auto"}}>
                                <TextArea className="no_border" readOnly style={{width: "565px", height: "150px"}}>{texttemplate.text}</TextArea>
                            </Message>
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' collapsing textAlign='right'>
                            {permission_PERM_SYS_15 && (
                                <>
                                <Button icon onClick={()=>setTextTemplateEdit(texttemplate)}><Icon name='edit' /></Button>
                                <Button 
                                    icon 
                                    onClick={()=>dispatch(showConfirm({
                                        cancelButton: t('dashboard:TEXTTEMPLATES.Abbrechen'),
                                        confirmButton: t('dashboard:TEXTTEMPLATES.Löschen'),
                                        message: t("dashboard:TEXTTEMPLATES.Textvorlage '") + texttemplate.name + t("dashboard:TEXTTEMPLATES.' wirklich löschen?"),
                                        onConfirm: ()=>dispatch(deleteTextTemplate(texttemplate))
                                    }))}
                                ><Icon name='trash' /></Button>
                                </>
                            )}
                        </Table.Cell>
                    </Table.Row>
                );
            }
            return <></>
        })
    }

    //new textTemplate
    let newTextTemplateRow = <></>;
    let primaryButtonDisabled = '';
    if (textTemplateNew !== null) {
        primaryButtonDisabled = 'disabled';
        newTextTemplateRow =  (
            <>
                <Table.Row>
                    <Table.Cell verticalAlign='top' collapsing>
                        <Input size='small' placeholder={t('dashboard:TEXTTEMPLATES.Name der Textvorlage')} onChange={(e, {value})=>{
                            let _textTemplate = Object.assign({}, textTemplateNew);
                            _textTemplate.name = value;
                            setTextTemplateNew(_textTemplate);
                        }} />
                    </Table.Cell>
                    <Table.Cell verticalAlign='top' collapsing>
                        <Message style={{maxWidth: "600px", overflow: "auto"}}>
                            <TextArea
                                className = "no_border"
                                placeholder = {t('dashboard:TEXTTEMPLATES.neuer Vorlagentext')} 
                                onChange = {(e, {value})=>{
                                    let _textTemplate = Object.assign({}, textTemplateNew);
                                    _textTemplate.text = value;
                                    setTextTemplateNew(_textTemplate);
                                }}
                                style={{'width':'565px', 'height':'100px'}}
                            ></TextArea>
                        </Message>
                    </Table.Cell>
                    <Table.Cell verticalAlign='top' textAlign='right' collapsing>
                        <Button primary icon onClick={()=>{
                            dispatch(createTextTemplate(textTemplateNew));
                            setTextTemplateNew(null);
                        }}><Icon name='save' /></Button>
                        <Button icon onClick={()=>setTextTemplateNew(null)}><Icon name='times' /></Button>
                    </Table.Cell>
                </Table.Row>
            </>
        )
    }

    
    

    return (
        <Modal
            open = {visible}
            className = 'wectexttemplates'
            size = 'medium'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => {
                setTextTemplateNew(null)
                setTextTemplateEdit(null)
                dispatch(hideTextTemplates())
            }}
        >
            <React.Fragment>
                <Modal.Content>
                <Header>{t('dashboard:TEXTTEMPLATES.Textvorlagen')}</Header>
                    <Table basic='very' selectable>
                        <Table.Body>
                            {texttemplates_list}
                            {newTextTemplateRow}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>{
                        setTextTemplateNew(null)
                        setTextTemplateEdit(null)
                        dispatch(hideTextTemplates())
                    }}><Icon name='times' />{t('dashboard:TEXTTEMPLATES.Schließen')}</Button>
                    {permission_PERM_SYS_14 && (
                        <Button 
                            primary 
                            icon 
                            labelPosition='left' 
                            disabled={primaryButtonDisabled}
                            onClick={()=>{
                                setTextTemplateNew({ id: 'ADD_'+Math.round(+new Date()/1000), name: '', type: 'texttemplate', domain: config.domain });
                            }}
                        ><Icon name='plus' />{t('dashboard:TEXTTEMPLATES.Hinzufügen')}</Button>
                    )}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECTextTemplates;