// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button } from 'semantic-ui-react';

// Redux
import { selectUsername, selectPassword, selectRPassword, selectEmail, selectType, selectTeams, isActive, selectDetails, selectLanguage, selectRole } from './user.slice';
import { createUser, hideModal, isDirty, isNewUserVisible } from './user.slice';
import { selectMandant, selectBaseProtocol, selectAuthUrl } from '../../WECorrect.slice';
import { selectToken } from '../login/login.slice';

// App
import WECUserBasics from './basics';
import WECUserDetails from './details';
import WECUserAssignments from './assignments';

// Styles
import './user.css';



/**
 * 
 */
const WECUserNew = () =>
{
    const { t } = useTranslation('user', 'common');

    const dispatch  = useDispatch();
    
    // User
    const username      = useSelector(selectUsername);
    const password      = useSelector(selectPassword);
    const rpassword     = useSelector(selectRPassword);
    const email         = useSelector(selectEmail);
    const type          = useSelector(selectType);
    const teams         = useSelector(selectTeams);
    const dirty         = useSelector(isDirty);
    const userDetails   = useSelector(selectDetails);
    const active        = useSelector(isActive);
    const language      = useSelector(selectLanguage);
    const role          = useSelector(selectRole);

    // Base
    const mandant       = useSelector(selectMandant);
    const authUrl       = useSelector(selectBaseProtocol) + '//' + useSelector(selectAuthUrl);
    const visible       = useSelector(isNewUserVisible);
    const token         = useSelector(selectToken);

    // 
    if (!visible) {
        return <></>
    }

    // Create button
    let creatUserParams = { 
        token : token, 
        mandant: mandant, 
        authUrl: authUrl, 
        username: username, 
        email: email, 
        password: password, 
        rpassword: rpassword, 
        details : userDetails,
        type: type, 
        teams: teams,
        role: role,
        active: active,
        language: language
    };
    for (const key in userDetails) {
        if (Object.hasOwnProperty.call(userDetails, key)) {
            creatUserParams[key] = userDetails[key];
        }
    }
    const create_button = dirty ? <Button color='green' onClick={() => dispatch(createUser(creatUserParams))}>{t('user:NEW.BUTTONS.Create')}</Button> : <></>;
    
    return (
        <Modal open={true} className='wecuser_new' size='large' closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Content>
                <Header>{t('user:NEW.LABELS.Benutzer anlegen')}</Header>
                <WECUserBasics noReadOnly={true} />
                <WECUserDetails />
                <WECUserAssignments />
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => dispatch(hideModal({ modal : 'NewUser' }))}>{t('user:NEW.BUTTONS.Abort')}</Button>
                {create_button}
            </Modal.Actions>
        </Modal>
    );
}

export default WECUserNew;