import { createSlice } from '@reduxjs/toolkit';
import { fetchDELETE, fetchPOST, fetchPUT } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice'




export const textTemplatesSlice = createSlice({
  name: 'textTemplates',
  initialState: {
    visible: false,
    textTemplates: null
  },

  reducers: {
    showTextTemplates: (state) => {
      state.visible = true;
    },
    hideTextTemplates: (state) => {
      state.visible = false;
      state.textTemplates = null;
    },
    setTextTemplates: (state, actions) => {
      state.textTemplates = actions.payload;
    }
  }
});
export const { showTextTemplates, hideTextTemplates, setTextTemplates } = textTemplatesSlice.actions;
export const isVisible = (state) => state.textTemplates.visible;
export const getTextTemplates = (state) => state.textTemplates.textTemplates;

export default textTemplatesSlice.reducer;



export function updateTextTemplate(textTemplate) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof textTemplate.id !== 'undefined' && textTemplate.id) {
        console.log('[textTemplate]', textTemplate)
        dispatch(fetchPUT(url + '/api/latest/'+textTemplate.domain+'/texttemplate/'+textTemplate.id, textTemplate)).then(
          () => {
            dispatch(addMessage({type: 'NOTICE', text: 'Textvorlage ' + textTemplate.name + ' wurde gespeichert'}));
            dispatch(setTextTemplates(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}



export function createTextTemplate(textTemplate) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof textTemplate.id !== 'undefined' && textTemplate.id) {
        dispatch(fetchPOST(url + '/api/latest/'+textTemplate.domain+'/texttemplate', textTemplate)).then(
          () => {
            dispatch(addMessage({type: 'NOTICE', text: 'Textvorlage ' + textTemplate.name + ' wurde angelegt'}));
            dispatch(setTextTemplates(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}



export function deleteTextTemplate(textTemplate) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.wec.baseProtocol + '//' + state.wec.backendUrl;
      if (typeof textTemplate.id !== 'undefined' && textTemplate.id) {
        dispatch(fetchDELETE(url + '/api/latest/'+textTemplate.domain+'/texttemplate/'+textTemplate.id)).then(
          () => {
            dispatch(addMessage({type: 'NOTICE', text: 'Textvorlage ' + textTemplate.name + ' wurde gelöscht'}));
            dispatch(setTextTemplates(null))
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
}