import { createSlice } from '@reduxjs/toolkit';
import { fetchPOST } from "../toolbox/requestor.slice";
import { addMessage } from '../snackbar/snackbar.slice';



export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    username  : '',
    password  : '',
    token     : '',
    loggedIn  : false,
    config    : false,
    tags      : [],
    user      : null,
    domain    : '',
    mandant   : ''
  },

  reducers: {
    changeUsername: (state, action) => { state.username = action.payload.username; },
    changePassword: (state, action) => { state.password = action.payload.password; },
    showLogin: (state) => {
      state.loggedIn  = false;
      state.token     = '';
      state.username  = '';
      state.password  = '';
    },
    loginSuccess: (state, action) => {
      state.loggedIn  = true;
      state.token     = action.payload.token;
      state.user      = action.payload.user;
      state.domain    = action.payload.domain;
      state.password  = '';
    },
    loginFailed: (state, action) => {
      console.log(action.payload.message);
      state.loggedIn  = false;
      state.token     = '';
      state.username  = '';
      state.password  = '';
    },
    logoutSuccess: (state, action) => {
      state.loggedIn  = false;
      state.token     = null;
      state.password  = '';

      navigator.serviceWorker.ready
        .then( (registration) => {
          if (registration.active) {
            registration.active.postMessage({ type: 'CLEAR_TOKEN' });
          }
        });
    },
    logoutFailed: (state, action) => {
      console.log(action.payload.message);
      state.loggedIn  = false;
      state.token     = '';
      state.username  = '';
      state.password  = '';
    },
    setUserLoggedIn: (state, action) => {
      state.user      = action.payload;
    }
  },
});

export const { changeUsername, changePassword, showLogin, loginSuccess, loginFailed, logoutSuccess, logoutFailed, setUserLoggedIn } = loginSlice.actions;

export const selectUser     = (state) => state.login.user;
export const selectUsername = (state) => state.login.username;
export const selectPassword = (state) => state.login.password;
export const selectToken    = (state) => state.login.token;
export const selectDomain   = (state) => state.login.domain;
export const isLoggedIn     = (state) => state.login.loggedIn;

export default loginSlice.reducer;



/**
 * Asynchronous thunk action login
 * @param {string} props.mandant
 * @param {string} props.authUrl
 * @param {string} props.username
 * @param {string} props.password
 */
export function loginUser(props) {
  return async (dispatch) => {
    try {
      const optionsLogin = { domain:  props.mandant, user: props.username, pass : props.password };

      dispatch(fetchPOST(props.authUrl + "/api/login", optionsLogin)).then(
        (token) => {
          if (!token) {
            dispatch(addMessage({type: 'ERROR', header: 'Fehler', text: 'Login nicht möglich'}));
            dispatch(loginFailed('token: ' + token))
          } else {
            // store login token in service worker
            navigator.serviceWorker.ready.then( (registration) => {
              if (registration.active) {
                registration.active.postMessage({ type: 'SET_TOKEN', token: token.token, user: token.user });
              }
            });

            // store token and user info in store
            localStorage.setItem('login.token', token.token);
            localStorage.setItem('login.user', JSON.stringify(token.user));
            localStorage.setItem('login.domain', props.mandant);
            dispatch(loginSuccess({ token: token.token, user: token.user, domain: props.mandant }));
          }
        }
      ).catch((error)=>{
        console.error(error);
        dispatch(addMessage({type: 'ERROR', header: 'Login nicht möglich',  text: error.message}));
      });
    } catch (error) {
      console.error(error);
      dispatch(loginFailed(error))
      dispatch(addMessage({type: 'ERROR', header: 'Login nicht möglich', text: error.message}));
    }
  }
}



/**
 * Asynchronous thunk action login
 * @param {string} props.mandant
 * @param {string} props.authUrl
 * @param {string} props.token
 */
 export function checkTokenUser(props) {
  return async (dispatch) => {
    try {
      dispatch(fetchPOST(props.authUrl + "/api/login/token", { domain:  props.mandant, token: props.token })).then(
        (token) => {
          if (!token) {
            dispatch(addMessage({type: 'ERROR', header: 'Fehler', text: 'Token-Login nicht möglich'}));
            dispatch(loginFailed('token: ' + token))
            localStorage.removeItem('login.user');
            localStorage.removeItem('login.token');
            localStorage.removeItem('login.domain');
          } else {
            // store login token in service worker
            navigator.serviceWorker.ready.then( (registration) => {
              if (registration.active) {
                registration.active.postMessage({ type: 'SET_TOKEN', token: token.token, user: token.user });
              }
            });

            // store token and user info in store
            dispatch(loginSuccess({ token: token.token, user: token.user, domain: props.mandant }));
          }
        }
      ).catch((error)=>{
        dispatch(addMessage({type: 'ERROR', header: 'Login nicht möglich',  text: error.message}));
        localStorage.removeItem('login.user');
        localStorage.removeItem('login.token');
        localStorage.removeItem('login.domain');
      });
    } catch (error) {
      dispatch(loginFailed(error))
      dispatch(addMessage({type: 'ERROR', header: 'Login nicht möglich', text: error.message}));
      localStorage.removeItem('login.user');
      localStorage.removeItem('login.token');
      localStorage.removeItem('login.domain');
    }
  }
}



/**
 * Asynchronous thunk action logout
 */
export function logoutUser() {
  return async dispatch => {
    try {

      // remove localStorage login data
      localStorage.removeItem('login.user');
      localStorage.removeItem('login.token');
      localStorage.removeItem('login.domain');

      // redirect to login page
      dispatch(logoutSuccess());
      window.setTimeout(function() {
        window.location.reload();
      }, 500);
    } catch (error) {
      dispatch(logoutFailed(error))
    }
  }
}