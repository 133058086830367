// react base
import React from 'react';
import useImage from 'use-image';

// Konvajs
import Konva from "konva";
import { Rect, Image, Transformer } from 'react-konva';

// Styles
import './editor.css';



/**
 * Draw marker with transformer, numbers and color chooser
 * @param {Boolean} readOnly
 * @param {Object} shapeProps 
 * @param {Boolean} isSelected
 * @param {Function} onSelect
 * @param {Function} onChange
 * @param {Function} onChangeTransform
 * @param {Function} onDragStart
 * @param {Function} onDragEnd
 * @param {String} mode
 * @param {Boolean} isCorrectionMode
 * @param {Function} onMouseOver
 * @param {Function} onMouseOut
 * @param {Boolean} isDirty
 * @param {Function} onRightClick
 * @param {Function} onAbort
 * @param {Function} onSave
 * @param {Object} correction
 * @param {Boolean} permissionEdit
 */
const WECCorrectorEditorRectangle = ({ readOnly, shapeProps, isSelected, onSelect, onChange, onChangeTransform, onDragStart, onDragEnd, mode, isCorrectionMode, onMouseOver, onMouseOut, isDirty, onRightClick, onAbort, onSave, correction, permissionEdit }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [imageSave] = useImage('/media/icons/save.png');
  const [imageAbort] = useImage('/media/icons/abort.png');

  
  // Apply transformer  
  React.useEffect(() => {
    if (!readOnly && permissionEdit && isCorrectionMode && isSelected) {
      if (trRef.current) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }
  // eslint-disable-next-line
  }, [isSelected, isCorrectionMode]);


  // Flash shape 
  React.useEffect(() => {
    if (typeof shapeProps.flash !== 'undefined' && shapeProps.flash) {
      var period = 50;
      var anim = new Konva.Animation(frame => {
        shapeRef.current.opacity((Math.sin(frame.time / period) + 1) / 2);
      }, shapeRef.current.getLayer());
      anim.start();
      setTimeout(function(){ 
        anim.stop(); 
        shapeRef.current.opacity(0.5);
      }, 1000);
    } else {
      shapeRef.current.opacity(0.5);
    }
  })

  // Apply cache to shapes
  React.useEffect(() => {
    shapeRef.current.cache();
    shapeRef.current.getLayer().batchDraw();
  });

  // transformer is changing scale of the node and NOT its width or height
  // but in the store we have only width and height to match the data better we will reset scale on transform end
  const _transformer = readOnly || !permissionEdit ? null : (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);

    onChangeTransform({
      ...shapeProps,
      
      // dimensions
      width: Math.ceil(node.width() * scaleX),
      height: Math.ceil(node.height() * scaleY),

      // position
      x: Math.ceil(node.x()),
      y: Math.ceil(node.y()),
    });
  }
  
  return (
    <>
    {!permissionEdit && (
      <React.Fragment>
        <Rect
          shadowBlur = {10}
          opacity = {0.8}
          onClick = {(e) => { onSelect(); }}
          ref = {shapeRef}
          {...shapeProps}
          onContextMenu = {(event)=>{}}
          onMouseOver = {onMouseOver}
          onMouseOut = {onMouseOut}
          onTransformEnd={_transformer}
        />
        {isDirty && (
          <>
            <Image
              image={imageSave}
              shadowBlur = {10}
              width = {25}
              height = {25}
              x = {shapeProps.x + shapeProps.width + 10}
              y = {shapeProps.y}
              onClick = {onSave}
            />
            <Image
              image={imageAbort}
              shadowBlur = {10}
              opacity = {1}
              width = {25}
              height = {25}
              x = {shapeProps.x + shapeProps.width + 10}
              y = {shapeProps.y + 30}
              onClick = {onAbort}
            />
          </>
        )}
        {!readOnly && isSelected && mode !== 'drag' && (
          <Transformer
            rotateEnabled = {false}
            ref = {trRef}
            boundBoxFunc = {(oldBox, newBox) => {
              if (newBox.width < 10 || newBox.height < 10) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}

      </React.Fragment>
    )}
    {permissionEdit && (
      <React.Fragment>
        <Rect
          shadowBlur = {10}
          opacity = {0.8}
          onClick = {(e) => { onSelect(); }}
          ref = {shapeRef}
          {...shapeProps}
          draggable = {isCorrectionMode && !readOnly}
          onContextMenu = {(event)=>{
            event.evt.preventDefault();
            const { clientX: mouseX, clientY: mouseY} = event.evt;
            onRightClick({
              x: mouseX, 
              y: mouseY,
              correction: correction
            });
          }}
          onMouseOver = {onMouseOver}
          onMouseOut = {onMouseOut}
          onDragStart = {()=>{
            if (!readOnly && isCorrectionMode) {
              onDragStart();
            }
          }}
          onDragEnd = {(e) => {
            if (isCorrectionMode && !readOnly) {
              onDragEnd();
              onChange({
                ...shapeProps,
                x: e.target.x(),
                y: e.target.y()
              });
            }
          }}
          onTransformEnd={_transformer}
        />
        {isDirty && (
          <>
            <Image
              image={imageSave}
              shadowBlur = {10}
              width = {25}
              height = {25}
              x = {shapeProps.x + shapeProps.width + 10}
              y = {shapeProps.y}
              onClick = {onSave}
            />
            <Image
              image={imageAbort}
              shadowBlur = {10}
              opacity = {1}
              width = {25}
              height = {25}
              x = {shapeProps.x + shapeProps.width + 10}
              y = {shapeProps.y + 30}
              onClick = {onAbort}
            />
          </>
        )}
        {!readOnly && isSelected && mode !== 'drag' && (
          <Transformer
            rotateEnabled = {false}
            ref = {trRef}
            boundBoxFunc = {(oldBox, newBox) => {
              if (newBox.width < 10 || newBox.height < 10) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}

      </React.Fragment>
    )}
    </>
  );
};


export default WECCorrectorEditorRectangle;