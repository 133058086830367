// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Checkbox, Grid, Image } from 'semantic-ui-react';

// Redux
import { selectSettings, setSettings } from './user.slice';
import { selectUser } from '../login/login.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';


// Styles
import './user.css';




const WECUserSettings = () => {
    // global state
    const dispatch  = useDispatch();
    const settings = useSelector(selectSettings);
    const userLoggedIn  = useSelector(selectUser);

    // Permissions
    let permissionHandler = new WECPermissionHandler();
    const permission_PERM_SYS_03 = permissionHandler.hasPermission(userLoggedIn, 'PERM_SYS_03') && (userLoggedIn.type === 1 || userLoggedIn.type === 2);

    // translations
    const { t } = useTranslation('user', 'common');

    const active_email = settings && typeof settings.email !== 'undefined' && settings.email.value ? true : false;
    const active_internal = settings && typeof settings.internal !== 'undefined' && settings.internal.value ? true : false;;
    const active_autoExpandComments = settings && typeof settings.expandComments !== 'undefined' && settings.expandComments.value ? true : false;;
    
    return (
        <React.Fragment>
            <Grid>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={8}><label>{t('user:SETTINGS.LABELS.E-Mail Benachrichtigungen')}</label></Grid.Column>
                    <Grid.Column width={8}>
                        {permission_PERM_SYS_03 && (
                            <Checkbox toggle floated="left" checked={active_email} onChange={(e, {checked})=>dispatch(setSettings({type: 'email', name: 'E-Mail Benachrichtigungen', value: checked}))} />
                        )}
                        {!permission_PERM_SYS_03 && active_email && (
                            <Image src='media/icons/WE_correct_icon_bestaetigen.svg' size='mini' />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={8}><label>{t('user:SETTINGS.LABELS.interne Benachrichtigungen')}</label></Grid.Column>
                    <Grid.Column width={8}>
                        {permission_PERM_SYS_03 && (
                            <Checkbox toggle floated="left" checked={active_internal} onChange={(e, {checked})=>dispatch(setSettings({type: 'internal', name: 'interne Benachrichtigungen', value: checked}))} />
                        )}
                        {!permission_PERM_SYS_03 && active_internal && (
                            <Image src='media/icons/WE_correct_icon_bestaetigen.svg' size='mini' />
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{padding: '3px'}}>
                    <Grid.Column width={8}><label>{t('user:SETTINGS.LABELS.Kommentare automatisch aufklappen')}</label></Grid.Column>
                    <Grid.Column width={8}>
                        {permission_PERM_SYS_03 && (
                            <Checkbox toggle floated="left" checked={active_autoExpandComments} onChange={(e, {checked})=>dispatch(setSettings({type: 'expandComments', name: 'Kommentare automatisch aufklappen ', value: checked}))} />
                        )}
                        {!permission_PERM_SYS_03 && active_autoExpandComments && (
                            <Image src='media/icons/WE_correct_icon_bestaetigen.svg' size='mini' />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}

export default WECUserSettings;