// react base
import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useImage from 'use-image';

// Redux
import { setMousePosition, checkDeselect, setMarkerOption, setDragMode, setSelectMode, setSelectedColor, selectedColor, setSelectedTool, selectedTool  } from './editor.slice';
import { correctionMode, addMode } from './editor.slice';
import { scale, selectedCorrection, selectCorrection, unselectCorrection, setCorrection } from './editor.slice';
import { dataPage, dataCorrectionLevel, dataProject } from '../corrector.slice';
import { selectBaseProtocol, selectBackendUrl, selectConfig } from '../../../WECorrect.slice';

import { setColor, setTool, setToolMessage, getCorrection, setCorrectionMarker } from './dialogCorrection.slice';

// Konvajs
import { Stage, Layer, Rect, Image } from 'react-konva';

// App
import WECCorrectorEditorRectangle from "./rectangle"
import WECCorrectorEditorPageImage from "./pageImage"
import WECCorrectorEditorIcon from "./icon"

// Styles
import './editor.css';



/**
 * 
 * @returns 
 */
const WECCorrectorEditorEdit = () => {
  // gloabl state
  const dispatch = useDispatch();
  const selectedId = useSelector(selectedCorrection);
  const _Correction = useSelector(getCorrection);
  const c_addMode = useSelector(addMode);
  const e_correctionMode = useSelector(correctionMode);
  const c_project = useSelector(dataProject);
  const c_correctionLevel = useSelector(dataCorrectionLevel);
  const c_dataPage = useSelector(dataPage);
  const c_selectBaseProtocol = useSelector(selectBaseProtocol);
  const s_selectBackendUrl = useSelector(selectBackendUrl);
  const e_selectedColor = useSelector(selectedColor);
  const e_selectedTool = useSelector(selectedTool);
  const _config = useSelector(selectConfig);
  const _scale = useSelector(scale);
  
  // local state
  const [correctionLocal, setCorrectionLocal] = useState(_Correction);
  const [refresh, setRefresh] = useState(true);
  const [scaleLocal, setScaleLocal] = useState(_scale);
  const [offsetLocal, setOffsetLocal] = useState({x: 0, y: 0});

  //
  let _correctionLocal = Object.assign({}, correctionLocal);
  _correctionLocal.marker = Object.assign({}, correctionLocal.marker);

  // React Component References
  const stageRef = React.useRef();

  // measurements
  const pane_size = 500;
  const pane_margin = 100;

  // Zoom and center preview
  // eslint-disable-next-line
  useEffect(() => {
    if (refresh) {
      let new_offset = { x: 0, y: 0};

      // (1) rescale to 100%
      _correctionLocal.marker.x = _correctionLocal.marker.origin.x;
      _correctionLocal.marker.y = _correctionLocal.marker.origin.y;
      _correctionLocal.marker.width = _correctionLocal.marker.origin.width;
      _correctionLocal.marker.height = _correctionLocal.marker.origin.height;

      // (2) landscape or portrait
      const rect_size = _correctionLocal.marker.width >= _correctionLocal.marker.height ? _correctionLocal.marker.width : _correctionLocal.marker.height;
      const padding = { 
        x: (rect_size - _correctionLocal.marker.width) / 2,
        y: (rect_size - _correctionLocal.marker.height) / 2
      }
      
      // (2) calculate scale
      let new_scale = ((pane_size - (pane_margin * 2)) / rect_size) * 100;
      new_scale = new_scale > 500 ? 500 : new_scale;
      const fscale = new_scale / 100;
      
      // (3) calculate offsett
      new_offset.x = -1 * (correctionLocal.marker.origin.x * fscale - pane_margin) + padding.x * fscale;
      new_offset.y = -1 * (correctionLocal.marker.origin.y * fscale - pane_margin) + padding.y * fscale;

      // apply offset
      _correctionLocal.marker.x = (_correctionLocal.marker.x * fscale + new_offset.x) / fscale;
      _correctionLocal.marker.y = (_correctionLocal.marker.y * fscale + new_offset.y) / fscale;

      //
      setScaleLocal(fscale)
      setCorrectionLocal(_correctionLocal);
      setOffsetLocal(new_offset);
      setRefresh(false);
    }
  });

  // build up tools list
  let tools = {}
  _config[_config.domain].toolbar.tool.tools.forEach(tool => {
    tools[tool[0]] = {
      id: tool[0],
      name: tool[1],
      icon: tool[2],
      text: tool[3]
    }
  });

  // checks if page given  
  if (typeof c_dataPage === 'undefined' || c_dataPage === null) {
    return (<></>);
  }

  //
  let _corrections = [_correctionLocal];
  
  return (
    
    <Stage 
      ref = {stageRef}
      width = {500}
      height = {500}
      onMouseDown = {(event) => dispatch(checkDeselect(event)) }
      onTouchStart = {checkDeselect}
      onClick = {(event) => {}}
      onMouseMove = { (event) => dispatch(setMousePosition({ x: event.evt.offsetX, y: event.evt.offsetY })) }
    >
      <Layer>
        <WECCorrectorEditorPageImage 
          imageProps = {c_dataPage}
          imageURL = {c_selectBaseProtocol + '//' + s_selectBackendUrl + '/media/projects/' + c_project.domain + '/' + c_project.id + '/' + c_correctionLevel.id + '/pages/'} 
          scale = {scaleLocal} 
          offset = {offsetLocal}
          onClick = {()=>dispatch(unselectCorrection())}
        />
        {_corrections.map((correction, i) => {
          let shapeProps = Object.assign({}, correction.marker);
          let icon;
          switch (shapeProps.type) {


            case 'euro':
              icon = '/media/tools/' +  _config[_config.domain].toolbar.euro.icon
              shapeProps.x  = correction.marker.x * scaleLocal;
              shapeProps.y = correction.marker.y * scaleLocal;
              shapeProps.width  = correction.marker.width * scaleLocal;
              shapeProps.height = correction.marker.height * scaleLocal;
              return (
                <WECCorrectorEditorIcon
                  permissionEdit = {true}
                  readOnly={false}
                  key = {i}
                  id = {correction.id}
                  icon = {icon}
                  shapeProps = {shapeProps}
                  isSelected = {correction.id === selectedId}
                  isCorrectionMode = {e_correctionMode}
                  mode = {c_addMode}
                  onSelect = {() => dispatch(selectCorrection(correction.id))}
                  onChangeTransform = {(newAttrs) => {
                    if (e_correctionMode) {                    
                      let _correctionLocalChanged = Object.assign({}, _correctionLocal);
                      _correctionLocalChanged.marker = Object.assign({}, _correctionLocalChanged.marker);
                      let _correctionOrigin = Object.assign({}, _correctionLocal);
                      let _newAttrs = Object.assign({}, newAttrs);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));

                      // calculate local size and position
                      _correctionLocalChanged.marker.x = Math.ceil(_newAttrs.x / scaleLocal);
                      _correctionLocalChanged.marker.y = Math.ceil(_newAttrs.y / scaleLocal);
                      _correctionLocalChanged.marker.width = Math.ceil(_newAttrs.width / scaleLocal);
                      _correctionLocalChanged.marker.height = Math.ceil(_newAttrs.height / scaleLocal);
                      setCorrectionLocal(_correctionLocalChanged); 
                    }
                  }}
                  onChange = {(newAttrs) => {
                    if (e_correctionMode) {
                      let _correctionOrigin = Object.assign({}, _correctionLocal);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);

                      //
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));
                    }
                  }}
                  onDragStart = {() => { if (e_correctionMode) { dispatch(setDragMode()) } }}
                  onDragEnd = {() => { 
                    if (e_correctionMode) { 
                      dispatch(setSelectMode())
                      dispatch(selectCorrection(correction.id))
                    }
                  }}
                  onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                  onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                />
              );



            case 'ok':
              icon = '/media/tools/' +  _config[_config.domain].toolbar.ok.icon
              shapeProps.x  = correction.marker.x * scaleLocal;
              shapeProps.y = correction.marker.y * scaleLocal;
              shapeProps.width  = correction.marker.width * scaleLocal;
              shapeProps.height = correction.marker.height * scaleLocal;
              return (
                <WECCorrectorEditorIcon
                  permissionEdit = {true}
                  readOnly={false}
                  key = {i}
                  id = {correction.id}
                  icon = {icon}
                  shapeProps = {shapeProps}
                  isSelected = {correction.id === selectedId}
                  isCorrectionMode = {e_correctionMode}
                  mode = {c_addMode}
                  onSelect = {() => dispatch(selectCorrection(correction.id))}
                  onChangeTransform = {(newAttrs) => {
                    if (e_correctionMode) {                    
                      let _correctionLocalChanged = Object.assign({}, _correctionLocal);
                      _correctionLocalChanged.marker = Object.assign({}, _correctionLocalChanged.marker);
                      let _correctionOrigin = Object.assign({}, _correctionLocal);
                      let _newAttrs = Object.assign({}, newAttrs);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));

                      // calculate local size and position
                      _correctionLocalChanged.marker.x = Math.ceil(_newAttrs.x / scaleLocal);
                      _correctionLocalChanged.marker.y = Math.ceil(_newAttrs.y / scaleLocal);
                      _correctionLocalChanged.marker.width = Math.ceil(_newAttrs.width / scaleLocal);
                      _correctionLocalChanged.marker.height = Math.ceil(_newAttrs.height / scaleLocal);
                      setCorrectionLocal(_correctionLocalChanged); 
                    }
                  }}
                  onChange = {(newAttrs) => {
                    if (e_correctionMode) {
                      let _correctionOrigin = Object.assign({}, _correctionLocal);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);

                      //
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));
                    }
                  }}
                  onDragStart = {() => { if (e_correctionMode) { dispatch(setDragMode()) } }}
                  onDragEnd = {() => { 
                    if (e_correctionMode) { 
                      dispatch(setSelectMode())
                      dispatch(selectCorrection(correction.id))
                    }
                  }}
                  onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                  onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                />
              );



            case 'tool':
              icon = '/media/tools/' +  tools[shapeProps.tool].icon
              shapeProps.x  = correction.marker.x * scaleLocal;
              shapeProps.y = correction.marker.y * scaleLocal;
              shapeProps.width  = correction.marker.width * scaleLocal;
              shapeProps.height = correction.marker.height * scaleLocal;
              return (
                <WECCorrectorEditorIcon
                  permissionEdit = {true}
                  readOnly={false}
                  key = {i}
                  id = {correction.id}
                  shapeProps = {shapeProps}
                  isSelected = {correction.id === selectedId}
                  isCorrectionMode = {e_correctionMode}
                  mode = {c_addMode}
                  icon = {icon}
                  onSelect = {() => dispatch(selectCorrection(correction.id))}
                  onChangeTransform = {(newAttrs) => {
                    if (e_correctionMode) {                    
                      let _correctionLocalChanged = Object.assign({}, _correctionLocal);
                      _correctionLocalChanged.marker = Object.assign({}, _correctionLocalChanged.marker);
                      let _correctionOrigin = Object.assign({}, _correctionLocal);
                      let _newAttrs = Object.assign({}, newAttrs);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));

                      // calculate local size and position
                      _correctionLocalChanged.marker.x = Math.ceil(_newAttrs.x / scaleLocal);
                      _correctionLocalChanged.marker.y = Math.ceil(_newAttrs.y / scaleLocal);
                      _correctionLocalChanged.marker.width = Math.ceil(_newAttrs.width / scaleLocal);
                      _correctionLocalChanged.marker.height = Math.ceil(_newAttrs.height / scaleLocal);
                      setCorrectionLocal(_correctionLocalChanged); 
                    }
                  }}
                  onChange = {(newAttrs) => {
                    if (e_correctionMode) {
                      let _correctionOrigin = Object.assign({}, _correctionLocal);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);

                      //
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));
                    }
                  }}
                  onDragStart = {() => { if (e_correctionMode) { dispatch(setDragMode()) } }}
                  onDragEnd = {() => { 
                    if (e_correctionMode) { 
                      dispatch(setSelectMode())
                      dispatch(selectCorrection(correction.id))
                    }
                  }}
                  onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                  onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                />
              );
            
            
            
            default:
              shapeProps.x  = correction.marker.x * scaleLocal;
              shapeProps.y = correction.marker.y * scaleLocal;
              shapeProps.width  = correction.marker.width * scaleLocal;
              shapeProps.height = correction.marker.height * scaleLocal;
              return (
                <WECCorrectorEditorRectangle
                  permissionEdit = {true}
                  readOnly={false}
                  key = {i}
                  id = {correction.id}
                  mode = {c_addMode}
                  shapeProps = {shapeProps}
                  isSelected = {correction.id === selectedId}
                  isCorrectionMode = {e_correctionMode}
                  onSelect = {() => dispatch(selectCorrection(correction.id))}
                  onChangeTransform = {(newAttrs) => {
                    if (e_correctionMode) {                    
                      let _correctionLocalChanged = Object.assign({}, _correctionLocal);
                      _correctionLocalChanged.marker = Object.assign({}, _correctionLocalChanged.marker);
                      let _correctionOrigin = Object.assign({}, _correctionLocal);
                      let _newAttrs = Object.assign({}, newAttrs);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));

                      // calculate local size and position
                      _correctionLocalChanged.marker.x = Math.ceil(_newAttrs.x / scaleLocal);
                      _correctionLocalChanged.marker.y = Math.ceil(_newAttrs.y / scaleLocal);
                      _correctionLocalChanged.marker.width = Math.ceil(_newAttrs.width / scaleLocal);
                      _correctionLocalChanged.marker.height = Math.ceil(_newAttrs.height / scaleLocal);
                      setCorrectionLocal(_correctionLocalChanged); 
                    }
                  }}
                  onChange = {(newAttrs) => {
                    if (e_correctionMode) {
                      let _correctionOrigin = Object.assign({}, _correctionLocal);

                      // calculate origin width and height using _scale
                      newAttrs.width = Math.ceil(newAttrs.width / scaleLocal);
                      newAttrs.height = Math.ceil(newAttrs.height / scaleLocal);
                      newAttrs.x = Math.ceil((newAttrs.x + (-1 * offsetLocal.x)) / scaleLocal);
                      newAttrs.y = Math.ceil((newAttrs.y + (-1 * offsetLocal.y)) / scaleLocal);

                      // calculate with zoom for full view
                      _correctionOrigin.marker.x = Math.ceil(newAttrs.x * _scale);
                      _correctionOrigin.marker.y = Math.ceil(newAttrs.y * _scale);
                      _correctionOrigin.marker.width = Math.ceil(newAttrs.width * _scale);
                      _correctionOrigin.marker.height = Math.ceil(newAttrs.height * _scale);

                      //
                      dispatch(setCorrection(_correctionOrigin));
                      dispatch(setCorrectionMarker(_correctionOrigin.marker));
                    }
                  }}
                  onDragStart = {() => { if (e_correctionMode) { dispatch(setDragMode()) } }}
                  onDragEnd = {() => { 
                    if (e_correctionMode) { 
                      dispatch(setSelectMode())
                      dispatch(selectCorrection(correction.id))
                    }
                  }}
                  onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
                  onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
                />
              );
          }
        })}

      
        {_correctionLocal.marker.type === 'rect' && (
          <React.Fragment>
            <Rect width={500} height={40} fill='#f7f7f7' stroke='#cecece' strokeWidth={1} x={0} y={460}/>
            <WECCorrectorEditorNewColorChooser 
              colors = {_config[_config.domain].toolbar.area.colors} 
              selected = {e_selectedColor}
              stageRef = {stageRef}
              onclick = {(color) => {
                // change color in local component 
                _correctionLocal.marker.color = color;
                _correctionLocal.marker.fill = color;
                setCorrectionLocal(_correctionLocal)

                // change color in global editor
                dispatch(setMarkerOption({ type : 'color', correction : _correctionLocal.id, options : { color : color }}))
                dispatch(setColor(color))
                dispatch(setSelectedColor(color))
                
              }}
            />
          </React.Fragment>
        )}
        {_correctionLocal.marker.type === 'tool' && (
          <React.Fragment>
            <Rect width={500} height={40} fill='#f7f7f7' stroke='#cecece' strokeWidth={1} x={0} y={460}/>
            <WECCorrectorEditorNewToolChooser 
              tools = {_config[_config.domain].toolbar.tool.tools} 
              selected = {e_selectedTool}
              stageRef = {stageRef}
              onclick = {(tool) => {
                // change color in local component 
                _correctionLocal.marker.tool = tool[0];
                setCorrectionLocal(_correctionLocal)

                // change color in global editor
                dispatch(setMarkerOption({ type : 'tool', correction : _correctionLocal.id, options : { tool : tool[0] }}))
                dispatch(setTool(tool[0]))
                dispatch(setSelectedTool(tool[0]))
                dispatch(setToolMessage(tool[3]))
              }}
            />
          </React.Fragment>
        )}
      
      </Layer>
    </Stage>
  );
}

export default WECCorrectorEditorEdit;



/**
 * 
 * @param {Array} tools
 * @param {Function} onclick 
 * @param {String} selected
 * @param {ReactReference} stageRef
 * @returns 
 */
 const WECCorrectorEditorNewToolChooser = ({tools, selected, onclick, stageRef}) => {
  return (
    <React.Fragment>
      {tools.map((tool, i) => {
        return (
          <WECCorrectorEditorNewToolChooserImg cnt={i} tool={tool} selected={selected} onclick={onclick} stageRef={stageRef} />
        );
      })}
    </React.Fragment>
  );
}
/**
 * @param {Integer} cnt
 * @param {Object} tool
 * @param {Function} onclick 
 * @param {String} selected
 * @param {ReactReference} stageRef
 * @returns 
 */
const WECCorrectorEditorNewToolChooserImg = ({cnt, tool, selected, onclick, stageRef}) => {
  const strokeWidth = selected ? 2 : 1;
  const [image] = useImage('/media/tools/'+tool[2]);
  return (
    <Image
      key = {cnt}
      id = {'tc_' + tool[0]}
      colorChooser
      image={image}
      stroke = '#cecece'
      strokeWidth = {strokeWidth}
      x = {cnt * 30 + 10}
      y = {470}
      width = {20}
      height = {20}
      cornerRadius = {4}
      onClick = {() => {
        onclick(tool)
      }}
      onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
      onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
    />
  );
}



/**
 * 
 * @param {Array} colors
 * @param {Function} onclick 
 * @param {String} selected
 * @param {ReactReference} stageRef
 * @returns 
 */
const WECCorrectorEditorNewColorChooser = ({colors, selected, onclick, stageRef}) => {
  return (
    <React.Fragment>
      {colors.map((color, i) => {
        return (
          <Rect
            key = {i}
            id = {'cc_' + color}
            colorChooser
            fill = {color}
            stroke = '#cecece'
            strokeWidth = {1}
            x = {i * 30 + 10}
            y = {470}
            width = {20}
            height = {20}
            cornerRadius = {4}
            onClick = {() => {
              onclick(color)
            }}
            onMouseOver = {() => { stageRef.current.container().style.cursor = 'pointer' }}
            onMouseOut = {() => { stageRef.current.container().style.cursor = 'default' }}
          />
        );
      })}
    </React.Fragment>
  );
}