// react base
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// semantic ui
import { Modal, Header, Button, Icon, Divider, TextArea, Form, Container, List, Grid, Checkbox, Message } from 'semantic-ui-react';

// Redux
import { isVisibleMultiple, hideMessageMultiple, messageContent, setMessageContent, messageReciepent, sendMultipleMessage } from './message.slice';
import { getTextTemplates, setTextTemplates } from '../texttemplates/texttemplates.slice';
import { selectAttendees } from '../corrector/project/attendees.slice';
import { dataProject } from '../corrector/corrector.slice';
import { selectConfig, selectBaseProtocol, selectBackendUrl } from '../../WECorrect.slice';
import { fetchGET } from "../toolbox/requestor.slice";

// Styles
import './message.css';






/**
 *
 */
const WECMessageMultiple = () => {
    // local state
    const [visibleTextTemplate, setVisibleTextTemplate] = useState(null);
    const [onlyUsersWIP, setOnlyUsersWIP] = useState(false);
    const [usersWorkInProgress, setUsersWorkInProgress] = useState(false);

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisibleMultiple);
    const content = useSelector(messageContent);
    const reciepient = useSelector(messageReciepent);
    const attendees = useSelector(selectAttendees);
    const texttemplates = useSelector(getTextTemplates);
    const config = useSelector(selectConfig);
    const baseProtocol  = useSelector(selectBaseProtocol);
    const backendhUrl = baseProtocol + '//' + useSelector(selectBackendUrl);
    const project = useSelector(dataProject);

    // translations
    const { t } = useTranslation('dashboard', 'common');
  
    //
    useEffect(() => {
        if (!usersWorkInProgress && typeof project !== 'undefined' && typeof project.correctionLevel !== 'undefined' && typeof project.correctionLevel.id !== 'undefined') {
            dispatch(fetchGET(backendhUrl + '/api/latest/'+config.domain+'/correctionlevel/'+project.correctionLevel.id+'/uwip')).then(
                response => {
                    setUsersWorkInProgress(response);
                }
            );
        }
        if (visible && !texttemplates) {
            const options = { filter: { domain: config.domain}, sorting:{ name: 'ASC' } };
            dispatch(fetchGET(backendhUrl + '/api/latest/'+config.domain+'/texttemplates/?options=' + btoa(JSON.stringify(options)).replace(/=/g, '_'))).then(
                response => dispatch(setTextTemplates(response))
            );
        }
    })

    // 
    if (!visible) {
        return <></>
    }

    // 
    let roleAttendees = []
    let roleAttendeesIds = []
    reciepient.ids.forEach(roleId => {
        if (typeof attendees[roleId] !== 'undefined') {
            for (const key in attendees[roleId]) {
                
                if (Object.hasOwnProperty.call(attendees[roleId], key)) {
                    const attendee = attendees[roleId][key];
                    if (onlyUsersWIP && !usersWorkInProgress.includes(attendee.userid)) {
                        continue;
                    }
                    
                    roleAttendees.push(
                        <List.Item key={key}>
                            <List.Content>{attendee.details.forename} {attendee.details.surname}</List.Content>
                        </List.Item>
                    );
                    roleAttendeesIds.push(attendee.userid);
                }
            }
        }
    });

    //
    let currentTemplateText = '';
    const texttemplateItems = !texttemplates ? <></> : texttemplates.map((element, index)=>{
        if ((!visibleTextTemplate && index === 0) || index === visibleTextTemplate) {                                        
            currentTemplateText = element.text;
            return (
                <Form.Field>
                    <Message>
                        <Message.Header>{element.name}</Message.Header>
                        <TextArea className="no_border" style={{width: "565px", height: "150px", border: 'none', background: 'none', padding: '0px', marginTop: '10px'}} value={element.text} />
                    </Message>
                </Form.Field>
            );
        } else {
            return <></>;
        }
    });
    const maxTextTemplates = texttemplateItems.length - 1;
    
    return (
        <Modal
            open = {visible}
            className = 'wecmessage multiple'
            size = 'medium'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => dispatch(hideMessageMultiple())}
        >
            <Modal.Content>
                <Header>{t('dashboard:MULTIMESSAGE.Kontrolleure benachrichtigen')}</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Form>
                                <Form.Field>
                                    <label>{t('dashboard:MULTIMESSAGE.Nachricht')} </label>
                                    <TextArea placeholder={t('dashboard:MULTIMESSAGE.Nachricht hinzufügen')} onChange={(event)=>{dispatch(setMessageContent(event.target.value))}} value={content} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t('dashboard:MULTIMESSAGE.Textvorlagen')}</label>
                                </Form.Field>
                                {texttemplateItems}
                                <Form.Group>
                                    <Form.Field>
                                        <Button icon onClick={()=>setVisibleTextTemplate(visibleTextTemplate > 0 ? visibleTextTemplate-1 : 0)}>
                                            <Icon name='left arrow' />
                                        </Button>
                                        <Button onClick={()=>dispatch(setMessageContent(currentTemplateText))}>{t('dashboard:MULTIMESSAGE.Übernehmen')}</Button>
                                        <Button icon onClick={()=>setVisibleTextTemplate(visibleTextTemplate < maxTextTemplates ? visibleTextTemplate+1 : maxTextTemplates)}>
                                            <Icon name='right arrow' />
                                        </Button>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <label>{t('dashboard:MULTIMESSAGE.Empfängereinschränkungen')}</label>
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox toggle checked={onlyUsersWIP} onChange={()=>{ setOnlyUsersWIP(!onlyUsersWIP) }} label={{ children: t('dashboard:MULTIMESSAGE.Nur Kontrolleure benachrichtigen, die noch nicht abgeschlossen haben') }} />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form>
                                <Form.Field>
                                    <label>{t('dashboard:MULTIMESSAGE.Kontrolleure')}</label>
                                    <Container className='correctors'>
                                        <List>
                                            {roleAttendees}
                                        </List>
                                    </Container>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(sendMultipleMessage(roleAttendeesIds, content))} primary><Icon name='send' />{t('dashboard:MULTIMESSAGE.Benachrichtigen')}</Button>
                <Button size='mini' floated='right' icon labelPosition='left' onClick={()=>dispatch(hideMessageMultiple())}><Icon name='times' />{t('dashboard:MULTIMESSAGE.Schließen')}</Button>
            </Modal.Content>
        </Modal>
    );
}

export default WECMessageMultiple;