// react base
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// semantic ui
import { Modal, Header, Button, Label, Icon, Grid, Input, Divider, Checkbox } from 'semantic-ui-react';

// Redux
import { isVisible, hideRole, getRole, setRole, getDirty, saveRole } from './role.slice';
import { setRoles } from './roles.slice';
import { selectConfig } from '../../WECorrect.slice';

// Permissions
import WECPermissionHandler from '../toolbox/permission_handler';

// Styles
import './roles.css';




/**
 * 
 * @param {Object} role
 * @param {Array} perms
 * @param {Function} onSetRole
 * @param {Function} onRemoveUser 
 * @returns 
 */
const WECRolesEdit = ({role, perms, onSetRole}) => {
    if (typeof role === 'undefined' || !role) {
        return <></>
    }

    const handlePersmissionChange = (permission, state, permissions) => {
        let _role = Object.assign({}, role);
        if (typeof _role.permissions === 'undefined') {
            _role.permissions = Object.assign({});
        }
        _role.permissions = Object.assign([], _role.permissions);
        let found = false;
        for (const permID in _role.permissions) {
            if (Object.hasOwnProperty.call(_role.permissions, permID) && permission === _role.permissions[permID].id) {
                _role.permissions[permID] = Object.assign({}, _role.permissions[permID]);
                _role.permissions[permID].state = state;
                found = true;
            }
        }
        if (!found) {
            if (typeof permissions[permission] !== 'undefined') {
                let _perm = permissions[permission];
                _perm.state = state;
                _perm.id = permission;
                _role.permissions.push(_perm);
            }
        }
        onSetRole(_role);
    }

    //
    let grouped_perms = {};
    for (const key in perms) {
        if (Object.hasOwnProperty.call(perms, key)) {
            let _perm = Object.assign({id: key}, perms[key]);
            if (typeof role.permissions !== 'undefined' && role.permissions) {
                role.permissions.forEach(rolePerm => {
                    if (rolePerm.id === _perm.id && typeof rolePerm.state !== 'undefined') {
                        _perm.state = rolePerm.state;
                        _perm.checked = rolePerm.state;
                    }
                })
            }
            if (typeof grouped_perms[_perm.group] === 'undefined') {
                grouped_perms[_perm.group] = {name: _perm.group, permissions: []}
            }
            grouped_perms[_perm.group].permissions.push(_perm)
        }
    }

    //
    let groupList = [];
    for (const key in grouped_perms) {
        if (Object.hasOwnProperty.call(grouped_perms, key)) {
            const grouped_perm = grouped_perms[key];
            const permissionList = grouped_perm.permissions.map((permission) => {
                return (
                    <Grid.Row style={{padding: '3px'}}>
                        <Grid.Column width={4}><label>{permission.name}</label></Grid.Column>
                        <Grid.Column width={2}>
                            <Checkbox 
                                toggle 
                                id = {permission.id} 
                                checked = {permission.state}
                                onChange={(e, {checked})=>handlePersmissionChange(permission.id, checked, perms)} 
                            />
                        </Grid.Column>
                        <Grid.Column width={10}>{permission.description}</Grid.Column>
                    </Grid.Row>
                )
            });

            groupList.push(
                <>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Divider horizontal>{key}</Divider>
                    </Grid.Column>
                </Grid.Row>
                {permissionList}
                </>
            );
        }
    }

    //
    const error_username = role.name.length ? '' : 'error';
    const error_username_label = !role.name.length ? <Label basic color='red' pointing>Bezeichnung fehlt</Label> :  <></>;

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row><Grid.Column width={16}>&nbsp;</Grid.Column></Grid.Row>
                {role.type !== 'S_CORRECTOR' && role.type !== 'S_ADMIN' && role.type !== 'S_GRAPHIC' && (
                    <Grid.Row style={{padding: '3px'}}>
                        <Grid.Column width={4}><label>Bezeichnung</label></Grid.Column>
                        <Grid.Column width={12}>
                            <Input 
                                fluid
                                className = {error_username}
                                value = {role.name}
                                onChange = {(e, {value})=>{
                                    let _role = Object.assign({}, role);
                                    _role.name = value;
                                    onSetRole(_role);
                                }}
                            />
                            {error_username_label}
                        </Grid.Column>
                    </Grid.Row>
                )}
                {groupList}
            </Grid>
        </React.Fragment>
    );
}




/**
 *
 */
const WECRole = () => {

    // global state
    const dispatch  = useDispatch();
    const visible = useSelector(isVisible);
    const role = useSelector(getRole);
    const dirty = useSelector(getDirty);
    const config = useSelector(selectConfig);
    
    // permissions
    let permissionHandler = new WECPermissionHandler();
    const perms = permissionHandler.getPermissions();

    // 
    if (!visible) {
        return <></>
    }

    // Save button
    const _saveData = Object.assign({domain: config.domain}, role);
    const save_button = dirty > 0 ? <Button primary icon labelPosition='left' onClick={()=>{ dispatch(hideRole()); dispatch(saveRole(_saveData)); }}><Icon name='save' />Speichern</Button> : <></>;

    return (
        <Modal
            dirty = {dirty}
            open = {true}
            className = 'wecroles'
            size = 'small'
            closeIcon 
            closeOnEscape = {false} 
            closeOnDimmerClick = {false}
            onClose = {() => { dispatch(hideRole()); dispatch(setRoles(null)); }}
        >
            <React.Fragment>
                <Modal.Content>
                    {role.id && (<Header>Rolle {role.name} bearbeiten</Header>)}
                    {!role.id && (<Header>Rolle anlegen</Header>)}
                    <WECRolesEdit
                        dirty = {dirty}
                        role = {role}
                        perms = {perms}
                        onSetRole = {(role)=>dispatch(setRole(role))}
                        onRemoveUser = {(userId)=>{
                            let _user = Object.assign([], role.user);
                            let _role = Object.assign({}, role);
                            _user.forEach((usr, index) => {
                                if (usr.id === userId) {
                                    _user.splice(index, 1);
                                }    
                            });
                            _role.user = _user
                            dispatch(setRole(_role))
                        }}
                    />    
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='left' onClick={()=>{ dispatch(hideRole()); dispatch(setRoles(null)); }}><Icon name='times' />Abbrechen</Button>
                    {save_button}
                </Modal.Actions>
            </React.Fragment>
        </Modal>
    );
}

export default WECRole;